import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { reject } from 'q';
import { Subscription, Subject } from 'rxjs';
import { AuthService } from '../../api/auth.service';
import { IConfirmationReqBtnConfig, LoginGuardService } from '../../api/login-guard.service';
import { RoutesService } from '../../api/routes.service';
import { ChatService } from '../../chat/chat.service';
import { DataGuardService } from '../../core/data-guard.service';
import { LangService } from '../../core/lang.service';
import { parseSlugList, StyleprofileService } from '../../core/styleprofile.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { ENextBtnOpt, EResultsPageTypes, IAssessmentFrameworkDetail, IRefDoc, ReportOptions, TestFormConstructionMethod } from '../../ui-item-maker/item-set-editor/models/assessment-framework';
import { identifyQuestionResponseEntries } from '../../ui-item-maker/item-set-editor/models/expected-answer';
import { QuestionScore } from '../../ui-item-maker/view-g9-sample/view-g9-sample.component';
import { TextToSpeechService } from '../text-to-speech.service';
import { checkElementIsEntry, getElementChildren, IQuestionConfig, IQuestionRun } from '../../ui-item-maker/item-set-editor/models';
import { ElementType, IContentElement,  IScoredResponse, getElementWeight, IAssetImpressionConfig, shuffle } from '../models';
import { IMenuTabConfig } from '../../ui-partial/menu-bar/menu-bar.component';
import { StudentG9ConnectionService } from '../../ui-student/student-g9-connection.service';
import { randArrEntry } from '../../ui-testadmin/demo-data.service';
import { IPanelModuleDef } from '../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component';
import { CanvasService, PageSwitch } from '../canvas.service';
import { DrawingLogService } from '../drawing-log.service';
import { IContentElementCanvas, IContentElementCanvasPage } from '../element-render-canvas/model';
import { DrawDisplayMode } from '../element-render-drawing/constants';
import { IContentElementIframe } from '../element-render-iframe/model';
import { IEntryStateMcq } from '../element-render-mcq/model';
import { HyperlinkService, ILinkRequest } from '../hyperlink.service';
// import { ElementType, getElementWeight, IContentElement, IScoredResponse } from '../models';
import { ISectionDef, ISectionMeta, ITestDef, SectionDrawingCtx } from '../sample-questions/data/sections';
import { UrlLoaderService } from '../url-loader.service';
import { ZoomService } from '../zoom.service';
import { KNOWN_TEST_RUNNER_TAGS } from './tags/known-tags';
import { pageNodeDef } from './util/drawings';
import { getQuestionTitleFromMap, getQuestionTitles, getQuestionWordSlug } from './util/question-titles';
import { CALC } from '../widget-calculator/widget-calculator.component';
import {DevtoolsDetectService, IDevTools} from "../../core/devtools-detect.service";
import { Router } from '@angular/router';
import { CustomButtonPos, DEF_CUSTOM_BTN_BG_COLOR, DEF_CUSTOM_BTN_FG_COLOR } from 'src/app/ui-item-maker/models';
import { PageModalController, PageModalService } from 'src/app/ui-partial/page-modal.service';
import { G3_SUBMIT_BG_COLOR, G6_SUBMIT_BG_COLOR } from 'src/app/ui-student/pj-page-switcher/pj-page-switcher.component';
import { AccessibilityService } from '../accessibility.service';
import { lzCompressProps } from '../../core/util/lzstring';
import { ISubSessionAttemptDef } from 'src/app/ui-student/pj-map/pj-map.component';
import { BACKGROUND_COLORS, BackgroundFillService } from '../background-fill.service';
import { EditViewMode } from 'src/app/ui-item-maker/item-set-editor/models/types';
import { ASSESSMENT, isAssessmentABED } from 'src/app/ui-teacher/data/types';

export interface ITestState {
  languageCode: string;
  currentSectionIndex: number;
  currentQuestionIndex: number;
  currentReadingPassageId?: number;
  currentModuleId?: number;
  readSelItemId?: number;
  questionStates: any;
  isSubmitted?: boolean;
  notes?: string;
  currentSectionsAllowedIndex?: number;
}

interface ItemScore {
  //  itemScore: { 
  //   [entryId: number]: number;
  // };
  correctItemScore:number    // total of individual item's score array (itemScore)
  totalItemWeight: number;   //  total weight of an individual item
}

interface FinalReportStats {
  itemScore: { 
    [qId: number]: ItemScore;
  } 
  numSRQuestions : number;
  numCRQuestions : number;
  numCorrectSRQuestions : number;
  correctSRScore : number;
  totalSRScore : number;
  totalCRScore : number;
}

interface ReaderInfo {
  readerId?: string,
  canvasId?: string,
  itemLabel?: string
}

interface SaveQuestionPayload {
  test_question_id: number,
  test_question_version_id: number,
  question_index: number,
  question_caption: string,
  section_index: number,
  module_id: number,
  response_raw: string,
  response: string
}

interface QuestionSuggestion {
  id: number;
  versionId: number;
  state: any;
  changes: any;
  annotations: any;
}

interface ISectionData {
  id: number,
  sections_meta: string
}

enum ReaderTextMode {
  CLOSED = 'CLOSED',
  HALF = 'HALF',
  FULL = 'FULL',
}

enum PageMode {
  RESULTS_INTRO = 'RESULTS_INTRO',
  RESULTS_SUMMARY = 'RESULTS_SUMMARY',
  RESULTS_INSTRUCTIONS = 'RESULTS_INSTRUCTIONS',
  TEST_RUNNER = 'TEST_RUNNER'
}

const DEFAULT_LINEREADER_WIDTH = 1000;
const SECOND_MS = 1000;
const IDLE_CHECK_FREQENCY = 1000;  // in millisecond
const FPS_CHECK_FREQENCY = 1000;  // in millisecond
const GOOD_FPS = 30;  // fps above 30 is good
const POOR_FPS = 10;   // fps below 10 is poor
                      // fps between 10-30 is adequate
enum FPSStatus {
  GOOD = "GOOD",
  ADEQUATE = 'ADEQUATE',
  POOR = "POOR",
}

enum KnownDocumentItemSlugs {
  mult_table_12 = 'mult_table_12',
}
export interface ICustomConfirmTestDialogData {
  text: string;
  confirmMsg: string;
  cancelMsg: string;
}

enum TrModal {
  ACC_SETTINGS = 'ACC_SETTINGS',
}

export  const parseQuestionSaveError = (msg: string) => {
  switch (msg) {
    case 'NOT_BOOKED_APPL': return 'msg_no_longer_booked_err';
    case 'ATTEMPT_CLOSED': return 'msg_attempt_closed_err';
    case 'SESSION_CLOSED': return 'msg_session_closed_err';
    case 'SESSION_ENDED': return 'msg_session_ended';
    case 'MARKED_NO_ID': return 'msg_marked_no_id';
    case 'MARKED_ABSENT': return 'msg_marked_absent';
    case 'ATTEMPT_PAUSED': return 'msg_session_pause';
    case 'NOT_VERIFIED': return 'msg_not_verified';
    case 'SESSION_PAUSED': return 'msg_paused_err';
    case 'ERR_NEW_CONNECTION': return 'msg_err_new_connection';
  }
}

export const handleSubmitTestErr = (e, loginGuard: LoginGuardService, lang: LangService) => {
  loginGuard.confirmationReqActivate({
    caption: lang.tra('msg_may_not_submit_test') + ' ' + lang.tra(parseQuestionSaveError(e.message))
  });
}

@Component({
  selector: 'test-runner',
  templateUrl: './test-runner.component.html',
  styleUrls: ['./test-runner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TestRunnerComponent implements OnInit,AfterViewInit, OnDestroy, OnChanges {

  @Input() asmtFmrk: IAssessmentFrameworkDetail;
  @Input() attemptKey: string;
  @Input() autoScrollOnSelect: boolean = false;
  @Input() btnReviewSubmit: string = 'btn_review_submit'; // please deprecate
  @Input() checkChat: () => Promise<any>;
  @Input() checkTime: () => Promise<any>;
  @Input() currentSession: Object;
  @Input() currentTestDesign: ITestDef;
  @Input() customConfirmTestDialogData: ICustomConfirmTestDialogData;
  @Input() dateTimeStart: moment.Moment;
  @Input() documentItems: IRefDoc[];
  @Input() exitResults: () => Promise<any>;
  @Input() forceQuestionsSkippable: boolean = false;
  @Input() frameWorkTags: {slug:string}[];
  @Input() goToDashboard: () => any;
  @Input() helpPageItem: number;
  @Input() instit_group_id: number;
  @Input() isChatEnabled: boolean;
  @Input() isExitEnabled: boolean;
  @Input() isPreview: boolean;
  @Input() isHelpEnabled: boolean = true;
  @Input() isIssueReportingEnabled: boolean;
  @Input() isNavFooterDisabled: boolean;
  @Input() isOsslt:boolean; // deprecated
  @Input() isOssltTools:boolean; // deprecated
  @Input() isPrintMode: boolean;
  @Input() isSectionControlsEnabled: boolean = true;
  @Input() isShowingResults:boolean = false; 
  @Input() isShowQuestionLabel: boolean;
  @Input() isText2SpeechEnabled: boolean = true;
  @Input() isTimeEnabled: boolean = true;
  @Input() isToolExploration: boolean; // is this a duplicate of isOssltTools ? please deprecate
  @Input() moduleIdInit: number;
  @Input() noTestConfirmationRequired: boolean = false;
  @Input() questionIndexInit: number;
  @Input() questionSrcDb: Map<number, IQuestionRun>;
  @Input() questionStates: {[key: string]: any};
  @Input() regularTimeRemaining: string;
  @Input() rubricDownloadLink:string;
  @Input() saveQuestion: (data: any) => Promise<any>;
  @Input() savePosition: (data: any) => Promise<any>;
  @Input() logAssetView: (data: any) => Promise<any>;
  @Input() logItemView: (data: any) => Promise<any>;
  @Input() sectionIndexInit: number;
  @Input() sectionsAllowed?: number[];
  @Input() sessions: Array<Object> = [];
  @Input() studentG9Connection: StudentG9ConnectionService
  @Input() submitTest: (skipPost?: boolean) => Promise<any>;
  @Input() postSubmit: () => Promise<any>;
  @Input() testAttemptId: number
  @Input() testFormId: number;
  @Input() testFormType: string;
  @Input() isDevToolsLocked: boolean;
  @Input() testLang: string;
  @Input() testSessionId: number;
  @Input() testTakerName: string;
  @Input() attemptHash: string;
  @Input() testTakerPEN: number;
  @Input() defaultZoomInit: number;
  @Input() ignoreDevTools: boolean;
  @Input() ossltAsmtModules: any;
  @Input() isPj?: boolean;
  @Input() isG6?: boolean;
  @Input() isQuestionnaire?: boolean;
  @Input() isTeacherAdminQuestionnaire?: boolean;
  @Input() asmtSlug: ASSESSMENT;
  @Input() currentSubSession: ISubSessionAttemptDef; // NOT SAFE TO USE
  @Input() showFPS = false;
  @Input() isFromItemSetEditor = false;
  @Input() isMulTableEnabled?: boolean = true;
  @Input() isDictionaryEnabled?: boolean = false;
  @Output() refreshTimeRemaining = new EventEmitter()
  @Output() screenUnfreeze = new EventEmitter()
  @Output() backToMap = new EventEmitter()
  @Output() backToMenu = new EventEmitter()
  @Output() backToDashboard = new EventEmitter();
  @Output() endSection = new EventEmitter();
  @Output() exit = new EventEmitter();
  @Output() questionTitles = new EventEmitter();
  @Output() onEditItem = new EventEmitter();
  @Output() triggerPauseNotif = new EventEmitter();

  @Output() studentPosition = new EventEmitter();
  @Output() calcState = new EventEmitter();
  @Output() dictionaryState = new EventEmitter();
  @ViewChild('questionDisplay', { static: false }) questionDisplay: ElementRef<HTMLDivElement>;
  @ViewChild('rubricLink') rubricLinkRef : ElementRef;
  @ViewChild('topBar', { static: false }) topBar: ElementRef<HTMLDivElement>;
  @ViewChild('lineReader') lineReader: ElementRef<HTMLDivElement>
    
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.updateScreenShrinkFactor();
    this.updateDragZoomCorrection();
  }
  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent){
    this.mousePosition = { x: event.clientX, y: event.clientY };
    if(this.isResizeLineReaderWidth === true) this._resizeLineReaderWidth();
  }

  @HostListener('document:click', ['$event', '$event.target'])
  onGlobalClick(event: MouseEvent, targetElement: HTMLElement){
    if (!targetElement) return;
    const el = document.getElementById('section-navigation-dropdown-trigger');
    // el can be null in tests where there is no section navigation
    if (el){
      const clickedInside = el.contains(targetElement) || el === targetElement;
      if (!clickedInside){
        this.isSectionNavDropdownOpen = false;
      }
    }
  }

  TrModal = TrModal;
  constructor(
    public lang: LangService,
    private loginGuard: LoginGuardService,
    private routes: RoutesService,
    private router: Router,
    public auth: AuthService,
    public textToSpeech: TextToSpeechService,
    public chatService: ChatService,
    public whitelabel: WhitelabelService,
    private safeUrl: UrlLoaderService,
    private drawLog: DrawingLogService,
    private hyperLinkService: HyperlinkService,
    private styleProfile: StyleprofileService,
    private changeDetector: ChangeDetectorRef,
    private dataGuard: DataGuardService,
    private canvasService: CanvasService,
    private zoom: ZoomService,
    private devtoolsDetect: DevtoolsDetectService,
    private pageModalService: PageModalService,
    private accessibility: AccessibilityService,
    public bgFillService: BackgroundFillService
  ) { 
  }

  activeBookMarkId:string;
  activeBookmarkState: Map<string, boolean> = new Map();

  hasConfigurableAccSettings: boolean = false;
  pageModal: PageModalController;
  showSolution;
  defaultZoomLevel = 1.5;
  minZoomLevel = 0.5;
  maxZoomLevel = 3;
  zoomIncrement = 0.25;
  testState: ITestState;
  routeSub: Subscription;
  isSyncing: boolean;
  isShowingSectionInfo: boolean;
  isShowingPostambleInfo: boolean;
  postambleCallback: () => Promise<any>; 
  preambleIndex: number = 0;  
  postambleIndex: number = 0;
  isFormulasToggledOn: boolean;
  isCalcToggledOn: boolean;
  isDictionaryToggledOn: boolean;
  isHelpOverlay: boolean;
  sectionTimeStarted: number;
  sectionTimeRemaining;
  helpScreenLayout:any;
  ticker;
  questionResaveInterval;
  currentReadSelection:string;
  currentBookmark:string;
  itemLabel: string;
  readerInfo: ReaderInfo[];
  isSavingResponse: boolean;
  isQuietlySavingResponse: boolean;
  currentModal: any;
  isShowingReadingSelections: boolean;
  isShowingTime:boolean = false;
  testStartTime: number;
  isShowingChat;
  isShowingReport;
  isTestNavOnTop:boolean = false;
  issueReportMessage = new FormControl();
  isLineReaderActive: boolean;
  mousePosition: {x: number, y:number};
  lineReaderPosition: {left:number, top: number};
  isResizeLineReaderWidth: boolean = false;
  lineReaderWidth:number = DEFAULT_LINEREADER_WIDTH;
  isTestNavExpanded: boolean = true;
  isToolbarExpanded: boolean;
  activeReadingSelection: IQuestionConfig;
  resultQs: {sId: number, qId: number}[] = [];
  resultQsMap: Map<number, number> = new Map(); // qId -> sId identifies question belongs to which section
  documentViews: IMenuTabConfig<number>[];
  selectedDocumentId:number;
  drawingCtx;
  clearAllDrawings:boolean
  drawingPageIndexTracker = new Map();
  PageMode = PageMode;
  questionScores: Map<number, QuestionScore> = new Map();
  questionPScores: Map<number, number> = new Map();
  screenWidth;
  isShowFormulaSheet = false;
  isShowDocuments = false;
  // showEraser = false;
  // showLine = false;
  // showFreehand = false;
  // showHighlight = false;
  // showFill = false;
  toolToggles = {
    showFreehand: false,
    showLine: false,
    showEraser: false,
    showHighlight: false,
    showFill: false,
  }
  transValue
  drawMode = '';
  currIEZoom = 100
  isShowOverlay = false;
  showDeactivatedMode = false;
  isNotepadEnabled = false;
  isZoomIn = false;
  isZoomOut = false;
  overlayState ={
    "off":true,
    "on":false,
    "deactivate":false
  }
  pageMode : PageMode;
  pageModeFlow = [
    {slug: PageMode.RESULTS_INTRO, caption: 'tr_results_intro_title'}, 
    {slug: PageMode.RESULTS_SUMMARY, caption: this.isABED()? 'tr_results_summary_title_ABED' : 'tr_results_summary_title_ABED', isDetail:true}, 
    {slug: PageMode.RESULTS_INSTRUCTIONS, caption: this.isABED()? 'tr_results_instr_title_ABED' : 'tr_results_instr_title', isDetail:true}, 
    {slug: PageMode.TEST_RUNNER, caption: undefined}
  ];
  pageModeIndex : number;
  iframeUrl
  element:IContentElementIframe = {
     elementType:ElementType.IFRAME,
     url:''
  }
  count = 0; //for progressbar
  totalFilledBySection = {}; //for progressBar
  // public numSRQuestions : number;
  // public numCorrectSRQuestions : number;
  // public numCRQuestions : number;
  // public correctSRScore : number;
  // public totalSRScore : number;
  // public totalCRScore : number;
  finalReportStats: FinalReportStats;
  frameWorkTagsRef = new Map();
  isInitialized:boolean;
  helpPageState = {};
  KNOWN_TEST_RUNNER_TAGS = KNOWN_TEST_RUNNER_TAGS;
  reportOptions = ReportOptions
  questionsForNavigation = [];
  isSectionNavDropdownOpen:boolean = false;
  isSectionNavDropdownEnabled:boolean = false;
  private devtoolsSub: Subscription;
  isDebugMode:boolean;
  lastResponseDebug:{label:string, test_question_id:number, formatted_response:string, score:number, weight:number}
  lastFrameLoopCheck = new Date();
  frameCount = 0;
  backgroundTimeElapsed = 0;
  backgroundDiagnosticsInterval;
  currentFPS
  currentFpsStatus
  currentFpsStatusText
  FPSStatus = FPSStatus
  timeoutId
  userIdling = false;
  userInactive: Subject<any> = new Subject();
  linkReqSub = new Subscription();
  EditViewMode = EditViewMode;

  ngOnInit() {
    this.initTags(true); // simple collection of the tags
    this.pageModal = this.pageModalService.defineNewPageModal();
    this.isDebugMode = !! this.devtoolsDetect.debugModePass
    this.initZoom();
    this.zoom.updateScreenShrink(1);
    this.bgFillService.reset();
    const root = document.getElementsByTagName("html")[0];
    root.style.overflowX="auto";
    this.initToolbarOption();
    this.initTestDef();
    this.initTestState();
    this.initNavigationDropdown();
    this.initTags(); // todo: what exactly are we resetting after the tags have been obtained?
    this.cacheCalcTagDefaults();
    if (this.isChatEnabled){
      this.initChatPage();
    }
    if (this.isHelpEnabled){
      this.initHelpPage();
    }
    this.initDocuments();
    this.initHyperlinkService();
    this.initCanvasService();
    this.initTicker();
    this.setDefaultZwibblerContext();
    this.initFirstQuestion();
    this.setResultsIntroTitleSlug();
    this.selectPageMode(this.pageModeFlow.length - 1);
    this.onResize();
    this.checkForImmediateResults().then(()=> {
      this.isInitialized = true;
    });

    // setTimeout(()=> {
    //   console.log('getCurrentSectionPreambleContent', this.getCurrentSectionPreambleContent())
    // }, 4000)
    if (!this.isPreview){
      this.initializeDevToolsSub()
    }



    if(this.checkIsOsslt()){
      if((this.currentSession !== this.sessions[this.count])){
        this.count++ // increase count; to start check on next Session
      } 
      if( JSON.parse(sessionStorage.getItem("totalFilledBySection")) && (this.currentSession === this.sessions[this.count])){
        this.totalFilledBySection = JSON.parse(sessionStorage.getItem("totalFilledBySection"));
      } 
    }

    this.initAccessibility();
    if(this.showFPS){
      this.checkTimeOut();
      this.userInactive.subscribe(() => this.userIdling = true);
      this.initFpsCheck();
    }
  }

  numDebugAggTrigger = 0;
  async triggerDebugModeAgg(){
    this.numDebugAggTrigger ++;
    if (this.numDebugAggTrigger >= 10){
      this.numDebugAggTrigger = 0;
      this.triggerDebugMode();
    }
  }
  async triggerDebugMode(){
    const password  = prompt('Enter DEBUG');
    if (password){
      try {
        const {isValid} = await this.auth.apiCreate('public/student/debug', {password})
        if (isValid){
          this.isDebugMode = true;
          this.devtoolsDetect.debugModePass = password;
        }
      }
      catch(e){
        alert('Please contact technical support.')
      }
    }
    this.numDebugAggTrigger = 0;
  }

  initAccessibility() {
    try {
      this.accessibility.log.subscribe( this.logComponentProcessData );
    } catch(e) {}
    if(this.asmtFmrk.accessibilitySettings) {
      for(const settingProp of Object.keys(this.asmtFmrk.accessibilitySettings)) {
        const setting = this.asmtFmrk.accessibilitySettings[settingProp];
        if(setting.isConfigurable) {
          this.hasConfigurableAccSettings = true;
        }
        this.accessibility[settingProp] = setting.defaultVal;
      }
    }
  }

  logComponentProcessData = (data) => {
    this.logStudentAction('TEST-RUNNER-COMPONENT', data)
  }

  checkIsOsslt(){
    return this.isOsslt || this.checkTag('OSSLT'); // non-tag approach is deprecated
  }

  checkIsG9() {
    return this.checkTag('G9');
  }

  checkIsOssltTools(){
    return this.isOssltTools || this.checkTag('OSSLT_TOOLS'); // non-tag approach is deprecated
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.isInitialized && changes.isShowingResults && this.isShowingResults) {
      this.showResults();
    }
    if (changes.defaultZoomInit){
      this.initZoom();
    }
  }

  initZoom(){
    console.log('defaultZoomInit', this.defaultZoomInit)
    this.zoom.update(this.defaultZoomLevel * (this.defaultZoomInit || 1));
  }
  initializeDevToolsSub(){
    var userAgent = window.navigator.userAgent;
    if (!userAgent.match(/iPad/i) && !userAgent.match(/iPhone/i) && !this.ignoreDevTools) {
      // this.devtoolsSub = this.devtoolsDetect.sub().subscribe((devtools: IDevTools) => {
      //   if (devtools) {
      //     const { isOpen } = devtools
      //     if (isOpen) {
      //       alert('DevTools detected.')
      //       this.router.navigateByUrl(`${this.auth.getDashboardRoute(this.lang.c())}/main`)
      //     }
      //   }
      // })
    }
  }
  disableCalcSD = false;
  disableCalcRoot = false;
  disableCalcDecimal = false;
  disableCalcFraction = false;

  initTags(isSkipReset:boolean = false){
    // console.log('frameWorkTags', this.frameWorkTags);
    if (this.frameWorkTags){
      this.frameWorkTags.forEach(tag => {
        const key = (''+tag.slug).trim();
        this.frameWorkTagsRef.set(key, true);
      });
      if (!isSkipReset){
        this.resetFlag()
      }
    }
  }

  cacheCalcTagDefaults(){
    if (this.checkTag("CALC_DISABLE_SD")) {
      this.disableCalcSD = true
    } 
    else if (this.checkTag("CALC_DISABLE_ROOT")) {
      this.disableCalcRoot = true
    } 
    else if (this.checkTag("CALC_DISABLE_DECIMAL")) {
      this.disableCalcDecimal = true
    } 
    else if (this.checkTag("CALC_DISABLE_FRAC")) {
      this.disableCalcFraction = true
    }
  }

  initToolbarOption(){
    let defaults = {
      zoomIn : true,
      zoomOut: true,
      lineReader: true,
      hiContrast: true,
      toggleEditor: true,
      highlighter: true,
      eraser: true,
      notepad: true,
      infoButton: true,
      freehand: true,
    }
    if(this.isPj){
      defaults.toggleEditor = false;
      defaults.infoButton = false;
    }
    
    if(!this.asmtFmrk?.toolbarOptions) {
      this.asmtFmrk.toolbarOptions = defaults
    }
  }

  checkTag(tag:string){
    return this.frameWorkTagsRef.get(tag);
  }

  resultStates:{[key: string]: any} = {};
  resultQuestDefs: Map<number, IQuestionRun> = new Map();
  showResults(){
    this.selectPageMode(0); // Select first page mode
    this.resultQs = [];
    for(let section of this.testRunnerSections) {
      if(!section.disableScoring){
        for(let questionId of section.questions) {
          if(!this.questionSrcDb.get(questionId).isReadingSelectionPage) {
            // this.resultQs.push({qId: questionId, sIndex, qIndex});
            this.resultQs.push({sId: section.sectionId, qId: questionId});
            this.resultQsMap.set(questionId, section.sectionId)
            this.resultStates[questionId] = JSON.parse(JSON.stringify(this.getQuestionState(questionId)))
            const def:IQuestionConfig = JSON.parse(JSON.stringify(this.getQuestionDef(questionId)))
            this.resultQuestDefs.set(questionId, def)
          }
        }
      }
    }
  }

  getResultState(question:number) {
    return this.resultStates[question]
  }

  checkForImmediateResults(){
    return Promise.all(
      this.testRunnerSections.map((section, sectionIndex) => {
        return this.ensureSectionPathFill(sectionIndex, false, true)
      })
    )
    .then(() => {
      if(this.isShowingResults && this.isFlushNavigation()) {
        this.showResults();
        this.scoreAllQuestions();
      }
    })
  }

  getRefDocCaption = (refDoc: IRefDoc) => {
    return this.lang.c() === 'en' ? refDoc.caption : refDoc.captionFr;
  }

  initDocuments(){
    this.documentViews = [];

    if (this.documentItems){

      if (!this.isMulTableEnabled && this.docContainMultTable()){
        this.excludeMultTable();
      }

      Promise.all(
        this.documentItems.map(document => {
          this.documentViews.push({
            id: document.itemId,
            caption: this.getRefDocCaption(document),
          })
          return this.loadDocument(document.itemId);
        })
      )
      .then(()=>{
        if (this.documentItems){
          const firstDocument = this.documentItems[0];
          if (firstDocument){
            this.selectDocumentView(firstDocument.itemId)
          }
        }
      });
    }
  }

  isDocMultTable(doc: IRefDoc){
    return doc.slug === KnownDocumentItemSlugs.mult_table_12;
  }

  docContainMultTable(){
    const multTableItems = this.documentItems.filter(entry => this.isDocMultTable(entry));
    return multTableItems.length != 0;
  }

  excludeMultTable(){
    this.documentItems = this.documentItems.filter(entry => !this.isDocMultTable(entry));
  }

  initCanvasService() {
    this.canvasService.canvasPageNumChanged.subscribe(this.canvasPageChanged)
  }
  
  initHyperlinkService(){
    this.isShowingReadingSelections = false;
    this.hyperLinkService.linkRequestSecond.subscribe(this.onBookmarkRequest);
    this.linkReqSub.add(
      this.hyperLinkService.linkRequest.subscribe(this.onLinkRequest)
    );
    this.hyperLinkService.clearBookmarks.subscribe(this.onBookmarkClear);
    this.hyperLinkService.requestForLinkUpdate.subscribe(this.onLinkRequestFromCanvas);
    this.hyperLinkService.canvasBookmarkChanged.subscribe(this.setLinkInfo);
    this.hyperLinkService.linkRequest.next({
      readerElementId: undefined,
      readerId: undefined
    })
  }

  canvasPageChanged = (data:PageSwitch) => {
    const readerId = data.canvasId
    const pageNum = data.pageNum
    const leftId = this.getActiveQuestionId()
    const rightId = this.testState.readSelItemId
    if (this.isQuestionCanvas(leftId)) {
      const canvasObj = <IContentElementCanvas>this.getActiveQuestionContent().content[0]
      if (canvasObj.readerId == readerId && readerId) {
        this.leftPageId = pageNum;
      }
    }
    if (this.isQuestionCanvas(rightId)) {
      const canvasObj = <IContentElementCanvas>this.getQuestionDef(rightId).content[0]
      if (canvasObj.readerId == readerId && readerId) {
        this.rightPageId = pageNum;
      }
    }
  }

  initFirstQuestion(){
    this.scrollToQuestion()
    this.lastFillState = this.isQuestionFilled( this.getCurrentQuestionIndex() );
    this.getIframeURL()
    this.initText2Speech();
    this.setActiveReadingSelection();
    if (!this.hasPreambleContent()){
      this.markActiveQuestionAsStarted();
    }
    this.getReadingSelectionCanvases();
    this.reinitReadingSelection();
    this._logItemView();
    this._logAssetView();
    this.restartQuestionResaveInterval()
    this.updateSectionAccessDate();
    // console.log ('initFirstQuestion', this.isShowingLeft(), this.isShowingRight())
  }

  initLastQuestion(){
    this.initFirstQuestion(); // function seems to be generalized in first look TODO: rename the method and calls
  }

  initText2Speech(){
    if (this.whitelabel.getSiteFlag('IS_BCED')){ //deprecated
      if (!this.frameWorkTagsRef.get('ENABLE_LISTEN_TOOL')) {
        this.isText2SpeechEnabled = false;  
      }
    }
  }

  isProgressBarEnabled(){
    return !this.isFlushNavigation()
    // return !this.whitelabel.getSiteFlag('IS_BCED')
  }

  isInfoIconEnabled(){
    return !this.isFlushNavigation()
    // return !this.whitelabel.getSiteFlag('IS_BCED')
  }

  isFlushNavigation(){
    return this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_VEA') || this.whitelabel.isTestCenter();
  }

  useImagePreamble(){
    return this.isFlushNavigation() && !this.checkTag('TEXT_PREAMBLE')
  }

  useNextPrevButtons() {
    return this.isFlushNavigation() || this.asmtFmrk.nextButtonOpt === ENextBtnOpt.NEXT_PREV;
  }

  isUsingTemporaryNotes(){
    return this.checkIsOsslt() || this.whitelabel.getSiteFlag('IS_BCED');
  }

  setResultsIntroTitleSlug = () => {
    if(this.asmtFmrk.isOrale){
      for(let i = 0; i < this.pageModeFlow.length; i++){
        if(this.pageModeFlow[i].slug === PageMode.RESULTS_INTRO){
          this.pageModeFlow[i].caption = 'tr_results_intro_title_orale'
          return;
        }
      }
    }
  }

  isCustomNavbarResultsIntroPageTitle(pageMode){
    return pageMode === PageMode.RESULTS_INTRO && (this.asmtFmrk.customNavbarResultIntroPageTitleEN || this.asmtFmrk.customNavbarResultIntroPageTitleFR)
  }

  get customResultPage(){
    const { customResultPage, customResultPageFr} = this.asmtFmrk
    return this.lang.c() === 'en' ? customResultPage : customResultPageFr 
  }

  get customResultPageTitle(){
    const { customResultPageTitle, customResultPageTitleFr} = this.asmtFmrk
    return this.lang.c() === 'en' ? customResultPageTitle : customResultPageTitleFr 
  }

  getCustomNavbarResultsIntroPageTitle = () => {
    return this.lang.c() === 'fr' ? this.asmtFmrk.customNavbarResultIntroPageTitleFR : this.asmtFmrk.customNavbarResultIntroPageTitleEN;
  }

  getSectionSlug(){
    if (this.whitelabel.getSiteFlag('IS_BCED')){
      return 'Part';
    }
    else{
      return 'title_stage'
    }
  }

  getFlagSlug(isUnflag?:boolean){
     // todo:STYLEPROFILE all of these should be defined in a cascading style profile
    if (!isUnflag){
      if (this.whitelabel.getSiteFlag('IS_BCED')){
        return 'btn_flag_question_bc'
      }
      else if (this.whitelabel.getSiteFlag('IS_EQAO')){
        if (this.checkIsOsslt() || this.checkIsOssltTools() || this.isPj){
          return 'btn_flag_question'
        }
        else {
          return 'btn_flag_question_g9'
        }
      }
      else if (this.whitelabel.getSiteFlag('IS_ABED') || this.isCAEC()){
        return 'btn_bookmark_question'
      }
      return 'btn_flag_question'
    }
    else{
      if (this.whitelabel.getSiteFlag('IS_BCED')){
        return 'btn_unflag_question_bc'
      }
      else if (this.whitelabel.getSiteFlag('IS_EQAO')){
        if (this.checkIsOsslt() || this.checkIsOssltTools() || this.isPj){
          return 'btn_unflag_question'
        }
        else{
          return 'btn_unflag_question_g9'
        }
      }
      else if (this.whitelabel.getSiteFlag('IS_ABED')){
        return 'btn_unbookmark_question'
      }
      return 'btn_unflag_question'
    }
  }

  getOpenReadSelSlug(isOpen:boolean){
    
    if (isOpen){
      if (this.whitelabel.getSiteFlag('IS_BCED')){
        return 'btn_hide_read_sel_bc';
      }
      return 'btn_hide_read_sel';
    }
    if (this.whitelabel.getSiteFlag('IS_BCED')){
      return 'btn_view_read_sel_bc';
    }
    return 'btn_view_read_sel';

    
  }

  appendNum(num1, num2){
    return parseInt(num1.toString() + num2.toString())
  }

  setDefaultZwibblerContext() {
    let defaultStr = "zwibbler3."
    const pages = [];
    const obj = {
      id: 0,
      type: "BaseNode"
    }
    
    const addEntry2DrawingPages = (question, questDef)=>{
      if (this.isQuestionCanvas(question)) {
        const canvasContent = <IContentElementCanvas> questDef.content[0]
        // console.log(canvasContent)
        canvasContent.pages.forEach((page:IContentElementCanvasPage, pindex)=>{
          let thisId = this.appendNum(question, pindex)
          //if (pindex==0) thisId = question
          this.drawingPageIndexTracker.set(thisId, index)
          // console.log(thisId, this.drawingPageIndexTracker.get(thisId))
          pages.push(pageNodeDef(nextID))
          index++
          nextID++
        })
      } else {
        pages.push(pageNodeDef(nextID));
        // console.log(question, index)
        this.drawingPageIndexTracker.set(question, index)
        nextID++;
        index++
      }
    }

    

    pages.push(JSON.stringify(obj));
    const questions = this.getCurrentQuestions();
    let nextID = 1000000000
    this.drawingPageIndexTracker.clear();

    let index = 0;
    questions.forEach((question, index)=>{
      const questDef = <IQuestionConfig>this.getQuestionDef(question)
      addEntry2DrawingPages(question, questDef)
    })
    questions.forEach((question)=>{
      const readingSelections = this.getQuestionDef(question).readSelections;
      if (readingSelections) {
        readingSelections.forEach((readSel)=>{
          for (const [key, value] of this.questionSrcDb.entries() ) {
            const questDef = <IQuestionConfig>value
            if (questDef.label == readSel) {
              if (!this.drawingPageIndexTracker.has(key)) {
                addEntry2DrawingPages(key, questDef)
              }
            }
          }
        })
      }
    }) 
    // console.log("Default", JSON.parse("["+pages.toString()+"]"))
    defaultStr += "["+pages.toString()+"]";
    // console.log(defaultStr)
    this.drawingCtx = defaultStr;
    this.resetFlag()    
  }

  getZoomLevel() {
    return this.zoom.getZoom();
  }

  getScreenShrink() {
    return this.zoom.getScreenShrink();
  }

  getScreenShrinkZoom() {
    return this.zoom.getScreenShrinkZoom();
  }
  getZoomValue(){
    const ua = navigator.userAgent;
    if (ua.indexOf("iPad")!=-1 || ua.indexOf("iPhone")!=-1) {
      if (this.getZoomLevel() > this.defaultZoomLevel) {
        this.zoom.update(this.defaultZoomLevel);
      }
    }
    return this.getScreenShrinkZoom();
  }
  getScaleValue() {
    return `scale(${this.getZoomValue()})`;
  }

  saveDrawing(drawingContext:SectionDrawingCtx) {
    const drawingSection = drawingContext.section;
    const drawing = drawingContext.ctx;
    if (this.getCurrentSectionIndex() != drawingSection) {
      return;
    }

    this.drawingCtx = drawing
    return;
  }

  loadDrawing() {
    return this.drawingCtx
  }

  resetter = true;
  resetFlag() {
    this.resetter = false;
    this.changeDetector.detectChanges();
    this.resetter = true;
    setTimeout(() => {
      this.scrollToQuestion()
    }, 100)
  }

  onLinkRequest = (data:ILinkRequest) => {
    this.navigateSplitScreenItems(data);  
  }

  navigateSplitScreenItems = (data:ILinkRequest) => {
    if (!data.readerId && !data.readerElementId && !data.itemLabel) {
      this.closePassage()
    } 
    else {
      this.currentReadSelection = data.readerId;
      this.currentBookmark = data.readerElementId;
      this.itemLabel = data.itemLabel;
      if (!data.itemLabel){
        this.testState.currentReadingPassageId = this.getCurrentReadingPassageId();
      }
      else {
        this.testState.currentReadingPassageId = this.getCurrentReadingPassageId()
      };
      if (this.currentReadSelDisplayMode === ReaderTextMode.CLOSED){
        this.readSelViewHalf();
      }
        this.hyperLinkService.linkRequestSecond.next({
          readerElementId: this.currentBookmark,
          readerId: this.currentReadSelection,
          itemLabel: this.itemLabel,
          bookmarkId: data.bookmarkId
        });

      let readSelItemId = this.getActiveReadingSelectionId();
      if (this.isFromItemSetEditor && readSelItemId) this.getQuestionSuggestions(readSelItemId);

      this.onResize();
    }
  }

  onBookmarkRequest = (data:ILinkRequest) => {
    if(!this.activeBookmarkState.has(data.bookmarkId)) this.activeBookmarkState.set(data.bookmarkId, false);
    this.activeBookMarkId = data.bookmarkId;
    if (this.activeBookMarkId) {
      setTimeout(()=> {
        const searchForClass = `bookmark id-${this.activeBookMarkId}`;
        const els = document.getElementsByClassName(searchForClass);
        if (els && els[0]){
          for(let i=0; i<els.length; i++) {
            if(this.activeBookmarkState.get(data.bookmarkId)){
              els[i].classList.remove('is-active');
            } else {
              els[i].classList.add('is-active');
            }
          }
          if(this.activeBookmarkState.get(data.bookmarkId)){
            this.activeBookmarkState.set(data.bookmarkId, false);
          } else {
            this.activeBookmarkState.set(data.bookmarkId, true);
          }
          els[0].scrollIntoView({behavior: 'smooth'});
        }
      }, 300);
    }
  }

  onBookmarkClear = (data: ILinkRequest) => {
    this.activeBookmarkState.forEach((val, bookmarkId) => {
      if(val === true) {
        const searchForClass = `bookmark id-${bookmarkId}`;
        const els = document.getElementsByClassName(searchForClass);
        if(els && els[0]) {
          for(let i=0; i<els.length; i++) {
            els[i].classList.remove('is-active');
          }
          this.activeBookmarkState.set(bookmarkId, false);
        }
      }
    })
  }

  onLinkRequestFromCanvas = (data:ILinkRequest) => {
    if (this.currentBookmark != data.readerElementId || this.currentReadSelection != data.readerId) {
      this.hyperLinkService.linkRequestSecond.next({
        readerElementId: this.currentBookmark,
        readerId: this.currentReadSelection,
      })
      this.onResize();
    }
  }

  setLinkInfo = (data:ILinkRequest) => {
    // console.log("Test runner unset Bookmark")
    this.currentReadSelection = data.readerId;
    this.currentBookmark = data.readerElementId;
    this.leftPageId = 0;
    this.rightPageId = 0;
    this.itemLabel = undefined;
  }


  getCurrentReadingPassageId() {
    const questions = this.getCurrentQuestions();
    let readingPassageQuestionID = undefined;
    questions.forEach((questID)=>{
      const question = this.getQuestionDef(questID);
      const content = question.content;
      content.forEach((element)=>{
        if (element.elementType == ElementType.CANVAS ) {
          if (element["readerId"] == this.currentReadSelection) {
            readingPassageQuestionID = questID;
          }
        }
      })
    })
    return readingPassageQuestionID;
  }

  isQuestionCanvas(id) {
    const questDef = this.getQuestionDef(id);
    if (questDef && questDef.content && questDef.content.length>0) {
      if (questDef.content[0].elementType == ElementType.CANVAS && questDef.content[0]["pages"]) {
        return true;
      }
    }
    return false;
  }

  leftPageId=0;
  rightPageId=0;
  getCurrentLeftItemId() {
    if (this.isQuestionCanvas(this.getActiveQuestionId())) {
      return this.appendNum(this.getActiveQuestionId(), this.leftPageId)
    } else {
      return this.getActiveQuestionId()
    }
  }

  getCurrentRightItemId() {
    if (this.isQuestionCanvas(this.testState.readSelItemId)) {
      return this.appendNum(this.testState.readSelItemId, this.rightPageId)
    } else {
      return this.testState.readSelItemId;
    }
  }

  convertReadSelToTextLink(readerId) {
    let textLinkEl = {};
    if (readerId.canvasId) {
      textLinkEl = {
        readerId: readerId.canvasId,
        caption: readerId.caption,
        elementType: ElementType.TEXT_LINK,
        readingSelectionCaption: readerId.readingSelectionCaption,
        itemLabel: undefined
      } 
    } 
    else if (readerId.itemLabel) {
      textLinkEl = {
        readerId: undefined,
        caption: readerId.caption,
        elementType: ElementType.TEXT_LINK,
        readingSelectionCaption: readerId.readingSelectionCaption,
        itemLabel: readerId.itemLabel
      } 
    }
    
    return textLinkEl
  }

  isWidthConstrained() {
    return this.whitelabel.getSiteFlag("TEST_RUNNER_WIDTH_CONSTRAINT");
  }

  isBCED(){
    return this.whitelabel.getSiteFlag('IS_BCED');
  }

  isEQAO() {
    return this.whitelabel.getSiteFlag('IS_EQAO');
  }

  isNBED() {
    return this.whitelabel.getSiteFlag('IS_VEA');
  }

  isABED() {
    return this.whitelabel.isABED();
  }

  isCAEC() {
    return this.whitelabel.isTestCenter();
  }

  getWhitelabelFlag() {
    return this.whitelabel.getWhitelabelFlag();
  }

  flagCurrentQuestion = () => {
    const qState = this.getActiveQuestionState()
    qState.__meta.isFlagged = !qState.__meta.isFlagged;
  }

  flagKeyPress = (event: KeyboardEvent) => {
      this.flagCurrentQuestion()
  }

  documentMap:Map<number, IQuestionConfig> = new Map();
  activeDocument
  loadDocument(itemId:number){
    return new Promise((resolve, reject) => {
      const item = <any>this.questionSrcDb.get(+itemId);
      // console.log(+itemId, item)
      this.documentMap.set(+itemId, item);
      resolve(item);
    })
  }

  selectDocumentView(itemId:number){
    this.selectedDocumentId = +itemId;
    // console.log('this.activeDocument', +itemId,  this.activeDocument)
    this.activeDocument = this.documentMap.get(+itemId);

    this.logStudentAction("STUDENT_ACCESS_DOC", this.activeDocument);
  }

  ngAfterViewInit(){
    this.testStartTime = this.getCurrentTime();
  }

  ngAfterViewChecked(){
    this.frameCount++;
  }

  lastFillState:boolean;
  checkAutoScroll(){
    const currentFillState = this.isQuestionFilled( this.getCurrentQuestionIndex() );
    if (currentFillState !== this.lastFillState){
      if (this.autoScrollOnSelect && currentFillState){
        const el = document.getElementById('btn-submit-question');
        if (el){
          el.scrollIntoView({behavior: 'smooth', block: 'end'});
        }
      }
      this.lastFillState = currentFillState;
    }
  }

  getQuestionRunnerWidth(containerId:string){
    return 40;
  }
  getQuestionRunnerSpillover(containerId:string){
    return 0;
  }

  getIframeURL(){
    let url = window.location.protocol + "//" + window.location.host + "/assets/sci_calc/index.html"
    this.element.url = url;

  }
  setEraser(){
    this.toggleZwibbler();

    this.toggleTool('showEraser', 'SHOW_ERASER');
  }
  setHighlighter(){
    this.toggleZwibbler();

    this.toggleTool('showHighlight', 'SHOW_HIGHLIGHT');

    this.logTool("SHOW_HIGHLIGHT", this.toolToggles.showHighlight);
  }
  setLine(){
    this.toggleZwibbler();

    this.toggleTool('showLine', 'SHOW_LINE');
  }
  setFreehand(){
    this.toggleZwibbler();

    this.toggleTool('showFreehand', 'SHOW_FREEHAND');
  }
  setFill(){
    this.overlayState.off = true;
    this.overlayState.on = false;
    this.isShowOverlay = false;
    this.toggleTool('showFill', 'SHOW_FILL');
  }

  toggleTool(variableName: string, toolSlug: string) {
    if(this.toolToggles[variableName] == undefined) {
      return;
    }
    if(!this.overlayState.deactivate && this.toolToggles[variableName] == true) {
      this.disableOverlay();
    }
    for (const key in this.toolToggles) {
      if (this.toolToggles.hasOwnProperty(key)) {
        this.toolToggles[key] = (key === variableName ? !this.toolToggles[key] : false);
      }
    }


    this.logTool(toolSlug, this.toolToggles[variableName]);
  }
  
  toggleZwibbler(){
   if (this.overlayState.off){
    this.overlayState.off = false;
     this.overlayState.on = true;
     this.isShowOverlay = true;
     return;
   }
   if (this.overlayState.deactivate){
     this.overlayState.deactivate = false;
     this.showDeactivatedMode = false;
   }
  }
  // draw-overlay-close
  disableOverlay(){
    if (!this.overlayState.deactivate){
         this.overlayState.deactivate = true;
         this.showDeactivatedMode = true;
       }
  }
 
  removeAllDrawings(){
      this.clearAllDrawings = !this.clearAllDrawings
  }

  getDrawingDisplayMode(){
    return DrawDisplayMode.TEST_RUNNER;
  }

  
  initTicker(){
    this.sectionTimeStarted = (new Date()).valueOf();
    // to do: this is not correct... it should be when they start a section and it should be persisted to the db
    this.ticker = setInterval(() => {
      const section = this.getCurrentSection();
      // console.log('section', section)
      this.checkAutoScroll(); // this has nothing to do with the timer countdown disp[lay]
      if (section && section.isTimeLimit) {
        let secondsRemaining = this.getCurrentSection().timeLimitMinutes * 60;
        let secondsSpent = ((new Date()).valueOf() - this.sectionTimeStarted) / 1000;
        secondsRemaining -= secondsSpent;
        let secondsDisplay = Math.round(secondsRemaining % 60);
        // console.log('getSectionTimeRemaining', secondsSpent)
        let minutesDisplay = Math.round((secondsRemaining - secondsDisplay) / 60);
        this.sectionTimeRemaining = this.leadingZero(minutesDisplay) + ':' + this.leadingZero(secondsDisplay);
      }
    }, 100);
    ////

    ///
    this.dataGuard.forceSaveSub().subscribe(req => {
      if (req){
        this._saveQuestion(true);
      }
    })
  }

  initChatPage(){
    this.chatService.isSupervisor = false;
    this.chatService.isInvigilator = false;
    this.chatService.isTestTaker = true;
    this.chatService.uid = this.auth.user().value.uid;
    this.chatService.markingPoolId = this.testSessionId;
    // this.chatService.selectedMarker = this.markerId;
    // this.chatService.instit_group_id = this.instit_group_id;
    this.chatService.group_id = this.instit_group_id;
    this.chatService.initSocket();
  }

  initHelpPage(){
    if (this.helpPageItem){
      this.helpScreenLayout = this.questionSrcDb.get(+this.helpPageItem);
    }
    else{
      // return this.auth.apiGet(this.routes.TEST_TAKER_DATA_DOWNLOAD, 1)
      //   .then(helpScreenLayout =>{
      //     this.helpScreenLayout = <any> helpScreenLayout;
      //   })
    }
  }

  private clearQuestionResaveInterval(){
    if (this.questionResaveInterval){
      clearInterval(this.questionResaveInterval);
    }
  }

  private restartQuestionResaveInterval(){
    this.clearQuestionResaveInterval();
    const ITEM_RESAVE_INTERVAL = 60*1000
    this.questionResaveInterval = setInterval(() => {
      if (!this.isShowingResults){
        // console.log('Saving question, time: ', new Date().toLocaleString())
        this._saveQuestion(true);
      }
    }, ITEM_RESAVE_INTERVAL)
  }
  
  ngOnDestroy() {
    clearInterval(this.ticker);
    this.clearQuestionResaveInterval();
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.devtoolsSub) {
      this.devtoolsSub.unsubscribe()
    }
    if(this.linkReqSub) {
      this.linkReqSub.unsubscribe();
    }
    if(this.backgroundDiagnosticsInterval){
      clearInterval(this.backgroundDiagnosticsInterval);
    }
    this.bgFillService.reset();
  }
  toggleTextToSpeech() {
    this.textToSpeech.toggle();
    this.logTool("TTS", this.textToSpeech.isActive);
  }
  isTextToSpeechActive() {
    return this.textToSpeech.isActive;
  }

  toggleHelpScreen() {
    if(this.isHelpOverlay){
      // document.getElementById("quest-cont").style.display = "flex"
      this.isHelpOverlay = false;
    }
    else{
      // document.getElementById("quest-cont").style.display = "none"
      this.isHelpOverlay = true;
    }

    this.logTool("HELP", this.isHelpOverlay);
  }
  getHelpScreenLayout(){
    //console.log(this.helpScreenLayout);
    return this.helpScreenLayout;
  }
  _saveQuestion(isQuietSave:boolean=false) {
    if(this.isShowingPostambleInfo) {
      return Promise.resolve(); // Don't save when showing postamble info, and don't show error message either.
    }

    if (this.isSavingResponse || this.isShowingResults) {
      return Promise.reject();
    }
    this.isSavingResponse = true;

    this.isQuietlySavingResponse = isQuietSave;
    this.isTestNavOnTop = true
    return new Promise<void>( async (resolve, reject) => {

      // CLOSER_LOOK_20210807 the diff here looked really funny, it looked like the saveQuestion got removed (not referenced anywhere else). (used the current change to preserve it)
      /**
       * T
       * @param data - The payload from a question
       * @returns 
       */
      const saveQuestionAndHandleErrors = async (data: SaveQuestionPayload): Promise<void> => {
        return new Promise<void>(async (resolveSave, rejectSave): Promise<void> => {
          // console.log('saving question: ', data.question_caption)
          this.isSavingResponse = true;
          this.isQuietlySavingResponse = isQuietSave;
          const test_question_id = this.getActiveQuestionId();
          const response_raw = JSON.stringify(this.getActiveQuestionState());
          if (this.isDebugMode) {
            try {
              const {
                formatted_response,
                score,
                weight
              } = await this.auth.apiPatch('public/student/debug', test_question_id, { response_raw, password: this.devtoolsDetect.debugModePass })
            
              this.lastResponseDebug = {
                label: this.getQuestionLabel(test_question_id),
                test_question_id: test_question_id,
                score,
                weight,
                formatted_response
              }
            }
            catch (e) {
              this.lastResponseDebug = null;
              console.error('Error capturing last response')
            }
          }
          this.saveQuestion(
            lzCompressProps({
              test_question_id: data.test_question_id,
              test_question_version_id: data.test_question_version_id,
              question_index: data.question_index,
              question_caption: data.question_caption,
              section_index: data.section_index,
              module_id: data.module_id,
              response_raw: data.response_raw,
              response: data.response,
              attempt_hash: this.attemptHash
            }, ['response_raw', 'response'])
          ).then((res) => {
            if (res && res.activeSubSession) {
  
            }
            this.isSavingResponse = false;
            this.isTestNavOnTop = false
            this.isQuietlySavingResponse = false;
            this.logDrawing()

            resolveSave();
          })
            .catch((e) => {
              this.isSavingResponse = false;
              this.isQuietlySavingResponse = false;
              this.isTestNavOnTop = false
              const questionSaveErr = parseQuestionSaveError(e.message);
  
              console.log(questionSaveErr, 'ERROR');
              console.log(e.message, 'error message');
              if (!questionSaveErr) {
                this.loginGuard.confirmationReqActivate({
                  caption: 'msg_save_question_err',
                  btnCancelConfig: {
                    hide: true
                  },
                  btnProceedConfig: {
                    caption: 'btn_retry'
                  },
                  confirm: () => {
                    return saveQuestionAndHandleErrors(data).then(() => {
                      resolveSave();
                    });
                  }
                })
              } else {
                if (e.message === 'ERR_NEW_CONNECTION') {
                  // force logout
                  console.log('new connection error')
                  this.loginGuard.confirmationReqActivate({
                    caption: 'msg_err_new_connection',
                    btnCancelConfig: {
                      hide: true
                    },
                    btnProceedConfig: {
                      caption: 'btn_ok'
                    },
                    confirm: () => {
                      // return to student dashboard or force logout
                      // return this.goToDashboard();
                      return this.auth.forceLogout(this.lang.getCurrentLanguage(), this.auth.myAccountType());
                    }
                  });
                  rejectSave(e);
                  return;
                }
                if (e.message === 'ATTEMPT_CLOSED') {
                  this.loginGuard.confirmationReqActivate({
                  caption: 'msg_attempt_closed_err',
                  btnCancelConfig: {
                    hide: true
                  },
                  btnProceedConfig: {
                    caption: 'btn_ok'
                  },
                    confirm: () => {
                      // return to student dashboard
                      return this.router.navigateByUrl(`/${this.lang.c()}/student/dashboard/main`);
                    }
                  });
                }
                if(questionSaveErr && this.isPauseOnSubmit()) {
                  this.loginGuard.confirmationReqActivate({
                    caption: this.lang.tra(questionSaveErr),
                    btnCancelConfig: {
                      hide: true
                    }
                  });
                }
                if (questionSaveErr && !this.isPauseOnSubmit()) {
                //   this.triggerPauseNotif.next('') // this is an issue
                // } else {
                  this.loginGuard.confirmationReqActivate({
                    caption: this.lang.tra(questionSaveErr),
                    btnCancelConfig: {
                      hide: true
                    },
                    btnProceedConfig: {
                      caption: 'btn_retry'
                    },
                    confirm: () => {
                      return saveQuestionAndHandleErrors(data).then(() => {
                        resolveSave();
                      });
                    }
                  });
                  rejectSave(e);
                }
              }
            })
        });
      }


      let splitScreenQuestionData;

      if(this.shouldSaveSplitScreenItem())
        splitScreenQuestionData = this.getQuestionDataToSave(true)

      // Save the main question response, set the saveReadSelection to true so it will save the split screen item once the main save succeed
      const mainQuestionData = this.getQuestionDataToSave()

      await saveQuestionAndHandleErrors(mainQuestionData).then(() => {       
        if (splitScreenQuestionData) {
          return saveQuestionAndHandleErrors(splitScreenQuestionData).then(() => {
            resolve();      
          })
        }
        resolve();
      })
    });
  }

  /**
   * Returns true if split screen item should be saved.
   * @returns true if yes
   */
  shouldSaveSplitScreenItem() : boolean {
    if (!this.activeReadingSelection)
      return false;

      const activeReadingSelectionId = this.getActiveReadingSelectionId();
      const activeQuestionId = this.getActiveQuestionId()
      const activeQuestionReadingSelections = this.getQuestionDef(activeQuestionId).readSelections;

      const scoredEntries = identifyQuestionResponseEntries(this.activeReadingSelection.content, []);
      const activeReadSelLabel = this.activeReadingSelection.label;
      return (
        activeReadingSelectionId !== undefined &&     // active reading selection id is present
        activeQuestionReadingSelections &&            // the current active question has reading selections, and
        activeQuestionReadingSelections.includes(activeReadSelLabel) && // the recorded curr reading selection is one of them
        this.getActiveQuestionReadSel() &&
        this.isQuestionActiveInSplitScreen(activeReadingSelectionId)  &&
        scoredEntries.length > 0
      )
  }

  getQuestionDataToSave(isSplitItem?: boolean): {
    test_question_id: number,
    test_question_version_id: number,
    question_index: number,
    question_caption: string,
    section_index: number,
    module_id: number,
    response_raw: string,
    response: string
  }{

    let test_question_id: number;
    let test_question_version_id: number;
    let question_index: number;
    let question_caption: string;
    let section_index: number;
    let module_id: number;
    let response_raw: string;
    let response: string;

    // For Split Screen
    if (isSplitItem) {
      const content = this.activeReadingSelection;
      test_question_version_id = content ? content.test_question_version_id : 0;
      test_question_id = this.getActiveReadingSelectionId();
      response_raw = JSON.stringify(this.getActiveReadingSelectionState());
      response = ''+(this.getActiveReadingSelectionResponse() || '');
      question_caption = this.getCurrentReadingSelectionTitle();

      // question_index = this.getCurrentReadingSelectionIndex();
      // section_index = this.getCurrentSectionIndex();

      // For now we're assuming these two indexes are being used to recored the last position of the student,
      // so the main item's section and question index. 
      section_index = this.getCurrentSectionIndex();
      question_index = this.getCurrentQuestionIndex();

      module_id = this.getCurrentModuleId();
    } else {
      // For the main question
      const content = this.getActiveQuestionContent();
      test_question_version_id = content ? content.test_question_version_id : 0;
      test_question_id = this.getActiveQuestionId();
      response_raw = JSON.stringify(this.getActiveQuestionState());
      question_index = this.getCurrentQuestionIndex();
      question_caption = this.getCurrentQuestionTitle();
      section_index = this.getCurrentSectionIndex();
      module_id = this.getCurrentModuleId();
      response = ''+(this.getActiveQuestionResponse() || '');
    }

    return {
      test_question_id,
      test_question_version_id,
      question_index,
      question_caption,
      section_index,
      module_id,
      response_raw,
      response,
    }
  }



  // private async getCurrentSSActiveStatus() {
  //   const test_session_id = this.testSessionId;
  //   const subsession_id = this.currentSubSession.id;

  //   return this.auth.apiFind(this.routes.STUDENT_ACTIVE_SUBSESSION_STATUS, {query: {test_session_id, subsession_id}})
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         const isStudentCurrSSActive = res[0];
  //         return isStudentCurrSSActive;
  //       }
  //     })
  //     .catch((e) => {
  //       return false;
  //     });
  // }

  private _logItemView()  {
    if (typeof this.logItemView !== 'function') return;
    const questionId = this.getActiveQuestionId();
    const itemToLog = <IAssetImpressionConfig> { 
      question_id: questionId,
      element_type: 'question' 
    };
    return this.logItemView(itemToLog);
  }

  private _logAssetView() {
    if (typeof this.logAssetView !== 'function') return;
    const question = this.getActiveQuestionContent();
    const questionId = this.getActiveQuestionId();
    const assets = question.content.reduce((acc, cv) => {
      const { elementType, images } = cv;
      switch (elementType) {
        case 'image': {
          let assetImages = [];
          if (images.default && images.default.image && images.default.image.assetId) {
            const { assetId, assetVersionId, elementType } = images.default.image;
            assetImages = [...assetImages, <IAssetImpressionConfig> { 
              question_id: questionId, 
              asset_id: assetId, 
              asset_version_id: assetVersionId, 
              element_type: elementType 
            }];
          }
          if (images.selected && images.selected.image && images.selected.image.assetId) {
            const { assetId, assetVersionId, elementType } = images.selected.image;
            assetImages = [...assetImages, <IAssetImpressionConfig> { 
              question_id: questionId, 
              asset_id: assetId, 
              asset_version_id: assetVersionId, 
              element_type: elementType 
            }];
          }
          return [...acc, ...assetImages ]
       }
       default:
        const { assetId, assetVersionId } = cv;
        if (assetId && assetVersionId) {
          const assetToLog = <IAssetImpressionConfig> { 
            question_id: questionId, 
            asset_id: assetId, 
            asset_version_id: assetVersionId, 
            element_type: elementType 
          };
          return [...acc, assetToLog ]
        }
        return acc;
      }
    }, []);
    return this.logAssetView(assets);
  }

  getCurrentModuleId(){
    return this.testState.currentModuleId;
  }

  getLogo(){
    let url;
    if (this.lang.c() === 'en'){
      url = this.whitelabel.getSiteText('asmt_logo_en')
    }
    else if (this.lang.c() === 'fr'){
      url = this.whitelabel.getSiteText('asmt_logo_fr')
    }
    return url;
  }

  getLogoSafe(){
    return this.safeUrl.sanitize(this.getLogo());
  }


  getActiveQuestionResponse() {
    const state = this.getActiveQuestionState();
    const responses = [];
    const entries = this.getQuestionStateEntries(state);
    entries.forEach(eRes => {
      if (eRes.selections && eRes.selections[0]) {
        const entryResponses = eRes.selections.map(s => s.i);
        responses.push(entryResponses.join(','));
      }
    });
    return responses.join(';');
  }

  getActiveReadingSelectionResponse() {
    const state = this.getActiveReadingSelectionState();
    const responses = [];
    const entries = this.getQuestionStateEntries(state);
    entries.forEach(eRes => {
      if (eRes.selections && eRes.selections[0]) {
        const entryResponses = eRes.selections.map(s => s.i);
        responses.push(entryResponses.join(','));
      }
    });
    return responses.join(';');
  }

  // getRouteParams(routeParams: any) {
  //   this.initTestDef();
  //   this.initTestState();
  // }

  testRunnerSections: Partial<ISectionDef>[];
  testRunnerPathSections: Map<number, ISectionDef[]>;
  initTestDef() {
    // reconstruct section order (taking into account choice paths)
    this.testRunnerSections = [];
    this.testRunnerPathSections = new Map();
    let currentConditional:{itemLabel:string, pathSectionList:ISectionDef[]};
    this.currentTestDesign.sections.forEach(section => {
      if (section.isConditional){
        if (currentConditional && currentConditional.itemLabel === section.conditionOnItem){
          currentConditional.pathSectionList.push(section);
        }
        else{
          currentConditional = {
            itemLabel: section.conditionOnItem, 
            pathSectionList: [section]
          }
          const nextIndex = this.testRunnerSections.length;
          this.testRunnerSections.push({
            questions:[],
          });
          this.testRunnerPathSections.set(nextIndex, currentConditional.pathSectionList);
        }
      }
      else{
        this.testRunnerSections.push(section)
      }
    });
    // compute section meta (mostly for the progress bar)
    let qsPrec = 0;
    this.testRunnerSections.forEach(section => {
      const qs = section.questions.length;
      let meta: ISectionMeta = { qs, qsPrec, };
      section.__meta = meta;
      qsPrec += qs;
    });
    const qsTotal = qsPrec;
    // store the total number of questions
    this.currentTestDesign.__meta = {qs: qsTotal};
    // compute the position of the marker on the progress bar
    this.testRunnerSections.forEach(section => {
      const m = section.__meta;
      const qIG = m.qsPrec + m.qs;
      const proportion = qIG / qsTotal;
      const markLoc = this.renderLocProp(proportion);
      section.__meta.markLoc = markLoc;
    });
    // in case we are restoring, initialize path fills
    
  }

  getSourceFormId(){
    return `${this.currentTestDesign?.sourceFormId} (v.${this.currentTestDesign?.testFormId})`
  }

  isLtrSplitView() {
    return this.checkTag(KNOWN_TEST_RUNNER_TAGS.SPLIT_VIEW_LTR)
  }

  isSplitScreenLinkInNavBar() {
    return !!this.asmtFmrk.splitScreenSettings?.isSplitScreenlinksInNavbar;
  }

  isHideReadSelLinksInQueHeader() {
    return this.asmtFmrk.splitScreenSettings?.isHideReadSelLinksInQueHeader;
  }

  isShowCutsomSplitScreenControls() {
    return !!this.asmtFmrk.splitScreenSettings?.isShowCutsomSplitScreenControls;
  }

  showToolbar() {
    return !this.inAmbleList() || this.checkTag(KNOWN_TEST_RUNNER_TAGS.SHOW_AMBLE_TOOLBAR)
  }

  updateScreenShrinkFactor(){
    this.screenWidth = window.innerWidth;
    let baseW = 900;
    const isSplitScreen = this.isShowingLeft() && this.isShowingRight();
    if (isSplitScreen || this.isFullScreen()){
      baseW = this.isLeftBarDisabled() ? 1200 : 1400;
    }
    baseW = baseW * this.defaultZoomLevel;
    if (this.screenWidth < baseW){
      this.zoom.updateScreenShrink(this.screenWidth / baseW);
    }
    else if (!isSplitScreen || this.isLeftBarDisabled()) { // && this.isShowingRight()
      // const maxW = this.isLeftBarDisabled() ? baseW*2 : 3000;
      this.zoom.updateScreenShrink(Math.min(3000, this.screenWidth) / baseW);
    }
  }

  updateDragZoomCorrection(){
    const cdkStyle:any = document.getElementById('cdk-font-size-override');
    if (cdkStyle){
      if (this.isFlushNavigation()){
        cdkStyle.innerText = `.cdk-drag-preview { font-size: ${this.getScreenShrinkZoom()}em; }`;
      }
      else{
        cdkStyle.innerText = `.cdk-drag-preview { font-size: ${this.getScreenShrinkZoom()}em; }`;
        // cdkStyle.innerText = `.cdk-drag-preview { font-size: ${this.zoomLevel}em; }`;
      }
    }
  }

  isFullScreen() {
    return this.inAmbleList();
  }

  private renderLocProp(p: number, asNum: boolean= false) {
    return Math.round(100 * p) + (asNum ? '' : '%');
  }

  showSectionInfo() {
    if (this.hasPreambleContent()) {
      this.isShowingSectionInfo = true;
      this.onResize();
    }
    this.scrollToQuestion();
  }

  showPostambleInfo(callback: () => Promise<any>) {
    if(this.hasPostambleContent()) {
      this.clearTools();
      this.isShowingPostambleInfo = true;
      this.postambleCallback = callback;
      this.onResize();
    }
    this.scrollToQuestion();
  }

  hidePostambleInfo() {
    this.isShowingPostambleInfo = false;
    this.onResize();
  }

  hideSectionInfo() {
    this.isShowingSectionInfo = false;
    this.lastFillState = this.isQuestionFilled(this.getCurrentQuestionIndex());
    this.markActiveQuestionAsStarted();
    this.scrollToQuestion();
    this.onResize();
  }

  getProgressContainer(){
    return this.ossltAsmtModules || this.asmtFmrk.partitions || [0,1,2,3,4,5]
  }
  getCurrentProgressBySession(session: Object, indexStart: number){

    if(!this.useProgressBySession()) {
      return undefined; 
    }

    let qsTotal = 0;
    let totalFilled = 0;
    
    const container = this.getProgressContainer();

    if(this.count > 0){ indexStart = indexStart + container.length; }

    if(session === this.sessions[this.count]){
      const currSection = this.getCurrentSection(); 
      const currSectionIndex = this.getCurrentSectionIndex();
      const numUnfilledQInSection = this.countNumCurrentQuestionsUnfilled();
      let totalFilledInSection = currSection.questions.length - numUnfilledQInSection;
      this.totalFilledBySection[currSectionIndex] = totalFilledInSection;
      
      for(let el in this.totalFilledBySection ){
        if(this.totalFilledBySection.hasOwnProperty(el)){
          totalFilled += parseInt(this.totalFilledBySection[el]) //sum of all totalFilledBysection
        }
      }

      for( let i=indexStart; i< container.length+indexStart; i++ ){
        qsTotal += this.currentTestDesign.sections[i].questions.length;
      }
      const proportion = !qsTotal ? 0 : (totalFilled / qsTotal); // sum of all totalFilledBySection divided by the total num of questions in session

    return this.renderLocProp(proportion, true);
    } 
  }


  getCurrentProgressLoc(asNum: boolean= false) {
    if(this.useProgressBySession()) {
      return undefined;
    }
    
    const section = this.getCurrentSection();
    const qIR = this.getCurrentQuestionIndex() + 1;
    const qsTotal = this.isPj ? (section.__meta.qs - 1) : this.currentTestDesign.__meta.qs 
    const qIG = this.isPj ? this.getCurrentQuestionIndex() : (section.__meta.qsPrec + qIR);
    const proportion = !(qsTotal) ? 0 : qIG/qsTotal;
    return this.renderLocProp(proportion, asNum);
  }

  initTestState() {

    let sectionIndex = this.sectionIndexInit;
    let minSectionAllowedIndex = undefined;
    if(this.sectionsAllowed) {
      minSectionAllowedIndex = 0;
      const minSectionNum = this.sectionsAllowed[minSectionAllowedIndex];
      const maxSectionNum = this.sectionsAllowed[this.sectionsAllowed.length - 1];
      const sectionIndexPre = +sectionIndex
      sectionIndex = Math.max(sectionIndex, minSectionNum);
      sectionIndex = Math.min(sectionIndex, maxSectionNum);
      if (sectionIndexPre !== sectionIndex){
        this.questionIndexInit = 0
      }
    }
    // console.log('questionStates', this.questionStates)
    this.testState = {
      languageCode: this.testLang,
      currentSectionIndex: sectionIndex,
      currentQuestionIndex: this.questionIndexInit,
      currentModuleId: this.moduleIdInit,
      questionStates: this.questionStates,
      currentSectionsAllowedIndex: minSectionAllowedIndex
    };

    console.log('sectionIndex', sectionIndex, this.testFormType, TestFormConstructionMethod.MSCAT)
    if (this.testFormType === TestFormConstructionMethod.MSCAT){
      this.loadMsCatModules();
    }
    this.initSection();
    this.updatePosition(sectionIndex, this.questionIndexInit);
    if (this.testState.currentQuestionIndex === 0) {
      if (this.hasPreambleContent()) {
        this.showSectionInfo();
        this.preambleIndex = 0;
      }
    }

  }

  loadMsCatModules(){
    const {currentSectionIndex, currentModuleId} = this.testState;
    let targetModuleId = currentModuleId; // clarification: the section index should always drive the module id, so this value doesnt really get used
    if (currentSectionIndex > 0){
      for (let i=1; i<=currentSectionIndex; i++){
        const sectionIndex = i;
        const moduleId = this.getNextModuleId(sectionIndex-1);
        this.loadQuestionsForModuleId(+moduleId, sectionIndex);
        targetModuleId = moduleId
      }
    } 
    if (currentSectionIndex === 0){
      targetModuleId = this.getSection(currentSectionIndex).moduleId
      this.loadQuestionsForModuleId(+targetModuleId, currentSectionIndex);
    }
  }

  initNavigationDropdown() {
    if (!this.asmtFmrk) return;
    const sections = this.asmtFmrk.sectionItems;
    if (!sections) return;
    const dropdownItems = Object.keys(sections).reduce((allSections, sectionId, sectionIndex) => {
      const { questions } = sections[sectionId];
      const questionsForNav = questions.reduce((allQuestions, q) => {
        if (!q.isAnchor) return allQuestions;
        const qAnchor = this.setupQuestionAnchor(q);
        if (qAnchor) {
          this.isSectionNavDropdownEnabled = true;
          return [...allQuestions, qAnchor];
        }
        return allQuestions;
      }, []);
      return [...allSections, ...questionsForNav];
    }, []);
    this.questionsForNavigation = dropdownItems;
  }

  setupQuestionAnchor = (q) => {
    const { lock } = q;
    if (!lock) return q;

    switch (lock.type) {
      case 'section': {
        const { sectionId } = lock.by;
        const questions = this.getSectionQuestionsById(sectionId);
        const allFilled = questions.every(q => {
          const qState = this.getQuestionState(q.id);
          return this.isQuestionStateFilled(qState);
        });
        if (allFilled) return q;
        return;
      }
      case 'item': {
        const { items } = lock.by;
        const allFilled = items.every(item => {
          const qState = this.getQuestionState(item.id);
          return this.isQuestionStateFilled(qState);
        })
        if (allFilled) return q;
        return;
      }
      case 'choice': {
        const { choiceId, entryId, item } = lock.by;
        const mcqButtonLabels = 'ABCDEFGHIJKLMNOP';
        const qState = this.getQuestionState(item.id);
        const isFilled = this.isQuestionStateFilled(qState);
        if (!isFilled) return;
        const qEntry = qState[entryId];
        if (qEntry.selectionsMap.has(mcqButtonLabels.indexOf(choiceId))) return q;
        return;
      }
    }
  }

  getSectionQuestionsById = id => {
    const sections = this.asmtFmrk.sectionItems;
    const section = Object.keys(sections).reduce((acc, sId) => {
      if (sId === id) return sections[sId];
      return acc;
    }, null);
    return section ? section.questions : [];
  }

  toggleNavigationDropdown = () => {
    this.initNavigationDropdown();
    if (this.questionsForNavigation.length === 0) return;
    this.isSectionNavDropdownOpen = !this.isSectionNavDropdownOpen;
  };

  getNavQueAnchorLabel(question) {
    if (this.lang.c() === 'fr' && question.anchorLabelFr) return question.anchorLabelFr;
    return question.anchorLabel;
  }

  onQuestionNavigation = (question) => {
    const sections = this.asmtFmrk.sectionItems;
    const sectionQuestionIndexes = Object.keys(sections).reduce((allSections, sectionId, sectionIndex) => {
      const { questions } = sections[sectionId];
      const questionIndex = questions.reduce((allQuestions, q, qIndex) => {
        if (q.id === question.id) return qIndex;
        return allQuestions;
      }, null);
      if (questionIndex === 0 || questionIndex > 0) {
        return { sectionIndex, questionIndex };
      }
      return allSections;
    }, { sectionIndex: null, questionIndex: null });
    const { sectionIndex, questionIndex } = <any>sectionQuestionIndexes;
    if (sectionIndex !== null && questionIndex !== null) {
      this._saveQuestion().then(() => {
        this.restartQuestionResaveInterval();
        if (sectionIndex !== this.testState.currentSectionIndex) {
          this.sectionTimeStarted = (new Date()).valueOf();
        }
        this.updatePosition(sectionIndex, questionIndex);
        this.selectQuestion(questionIndex);
      });
    }
  }

  questionTitleMap;
  initSection(){
    this.initQuestionAllTitles();
  }
  
  initQuestionAllTitles(){
    this.questionTitleMap = getQuestionTitles({
      sections: this.currentTestDesign.sections, 
      questionSrc: this.questionSrcDb, 
      useQuestionLabel: this.currentTestDesign.useQuestionLabel, 
      lang: this.lang,
      questionWordSlug: this.currentTestDesign.questionWordSlug,
      isCountThroughSections: !!this.checkTag('QCOUNT_THRU_SECTIONS')
    });
    this.questionTitles.emit(this.questionTitleMap);
  }

  useSectionCaptions(){
    return this.currentTestDesign.useSectionCaptions;
  }

  getSectionCaption(sectionIndex:number){
    const section = this.getSection(sectionIndex);
    return section.caption
  }

  getCurrentSectionCaption(){
    const section = this.getCurrentSection();
    return section.caption
  }

  activateModal(caption: string, confirm: any, btnProceedConfig?: IConfirmationReqBtnConfig, btnCancelConfig?:IConfirmationReqBtnConfig) {
    let fontSize;
    if(this.isPj) {
      fontSize = 1.5;
    }
    this.loginGuard.confirmationReqActivate({
      caption,
      confirm,
      btnProceedConfig,
      btnCancelConfig,
      fontSize
    });
  }

  getNextPageSlug(){
    const questionWordSlug = this.getQuestionWordSlug();
    if(this.checkIsOssltTools()) {
      return 'osslt_next_page'
    }
    switch( questionWordSlug) {      
      case 'title_page':
        return 'osslt_next_page';
      case  'title_question':
      default:
        return 'btn_next_question_mpt'
    }
  }

  getCloseDrawingSlug(){
    if(this.isABED()){
      return 'abed_draw_tool_exit'
    }
    if(this.checkIsOsslt()){
      return 'draw_tool_exit';
    }
    return 'draw_tool_exit_g9';  
  }

  getReadselHalfSlug() {
    if(this.isABED() || this.isCAEC()){
      return 'abed_btn_readsel_half'
    }
    return 'btn_readsel_half';
  }

  getReadselCloseSlug() {
    if(this.isShowCutsomSplitScreenControls()){
      return 'btn_readsel_left'; 
    }
    return 'btn_readsel_close';
  }
  getReadselFullSlug() {
    if(this.isShowCutsomSplitScreenControls()){
      return 'btn_readsel_right'; 
    }
    return 'btn_readsel_full';
  }

  getRemoveDrawingSlug() {
    if(this.isABED() || this.isCAEC()){
      return 'btn_remove_drawings_abed'
    }
    return 'btn_remove_drawings';
  }

  allowQuickCollapse(){
    if (this.checkTag(KNOWN_TEST_RUNNER_TAGS.NO_LEFT_COLLAPSE)){
      return false;
    }
    if (this.whitelabel.getSiteFlag('IS_EQAO')){
      if (!this.checkTag(KNOWN_TEST_RUNNER_TAGS.ALLOW_LEFT_COLLAPSE)){
        return false
      }
    }    
    return true;
  }


  areTestQuestionsSkippable(){
    return !!this.getCurrentSection().areQuestionsSkippable;
  }


  isFormulasAvailable() {
    return this.getCurrentSection().hasFormulas;
  }
  isCalcAvailable() {
    return this.getCurrentSection().hasCalculator;
  }

  isDictionaryAvailable() {
    return !this.isShowingResults && (this.isDictionaryEnabled || this.asmtFmrk.toolbarOptions.dictionary)
  }

  isNotepadAvailable() {
    return this.getCurrentSection().hasNotepad;
  }

  getNextSectionIdxWhenConditional(currentIdx: number): number{
    if(!this.asmtFmrk.partitions[currentIdx].isConditional) return currentIdx;
    return this.getNextSectionIdxWhenConditional(currentIdx + 1);
  }

  getCurrentSectionIndex(): number {
    if (this.testState) {
      return this.testState.currentSectionIndex;
    }
    return -1;
  }
  getCurrentQuestionIndex(): number {
    if (this.testState) {
      return this.testState.currentQuestionIndex;
    }
    return -1;
  }

  getCurrentReadingSelectionIndex(): number {
    const currQuestions = this.getCurrentQuestions();
    if (currQuestions) {
      return currQuestions.indexOf(this.getActiveReadingSelectionId());
    }
    return -1;
  }

  getCurrentQuestionStates(): any {
    if (this.testState) {
      return this.testState.questionStates;
    }
    return {};
  }
  getCurrentSection(): ISectionDef {
    return this.getSection(this.getCurrentSectionIndex());
  }

  leadingZero(num) {
    if (num < 10) {
      return '0' + num;
    }
    return num;
  }
  getSectionTimeRemaining() {

  }

  getSection(i: number) {
    return this.testRunnerSections[i] || <any> {questions: []};
  }

  getCurrentQuestions(): number[] {
    if(this.isShowingResults) {
      return this.getResultsQuestions();
    } else {
      return (this.getCurrentSection()).questions || [];
    }
  }

  getResultsQuestions(): any[] {
    return this.resultQs.map( resultQ => resultQ.qId);
  }

  getQuestionWordSlug(config?: {isLowerCase?: boolean, isPlural?: boolean}) {
    return getQuestionWordSlug(this.currentTestDesign.questionWordSlug, config);
  }

  getQuestionWord() {
    return this.lang.tra(this.getQuestionWordSlug());
  }

  getCurrentQuestionTitle(){
    return (this.getQuestionTitle(this.getCurrentQuestionIndex())) ;
  }

  getCurrentReadingSelectionTitle(){
    return this.activeReadingSelection.caption;
  }
  
  getQuestionTitle = (qIndex: number):string => {
    const qId = this.getCurrentQuestions()[qIndex];
    const sId = this.getSectionIdForQueTitle(qId);
    return this.getQuestionTitleFromId(sId, qId);
  }

  getSectionIdForQueTitle = (qId: number) => {
    if(this.isShowingEmbeddedResults()){
      return this.resultQsMap.get(qId);
    }
    return this.getCurrentSection().sectionId;
  }

  getQuestionTitleFromId(sId: number, qId: number):string {
    return getQuestionTitleFromMap(this.questionTitleMap, sId, qId);
  }

  isShowingEmbeddedResults = () => {
    return this.isShowingResults && this.asmtFmrk.resultsPagetype === EResultsPageTypes.EMBEDDED;
  }

  getCurrentPoints(){
    const question = this.getActiveQuestionContent();
    if (question && !question.ishidePoints){
      return question.points;
    }
  }

  getActiveQuestionId() {
    if(this.isShowingSectionInfo) {
      const currSection = this.getCurrentSection();
      if(currSection.preamble) {
        return currSection.preamble;
      } else if(currSection.preambleList){
        return currSection.preambleList[this.preambleIndex];
      }
    }

    if(this.isShowingPostambleInfo) {
      const currSection = this.getCurrentSection();
      if(currSection.postambleList) {
        return currSection.postambleList[this.postambleIndex];
      }
    }

    const qId = this.getCurrentQuestions()[this.getCurrentQuestionIndex()];
    if (!qId) {
      // console.warn('Null question');
    }
    return qId;
  }

  getSectionPreambleContent(section: ISectionDef) {
    if(section.preamble) {
      return [this.getQuestionDef(section.preamble)];
    } else if (section.preambleList) {
      return section.preambleList.map((preambleId) => this.getQuestionDef(preambleId))
    }
    return undefined;
  }

  getCurrentSectionPreambleContent() {
    // replaced getSectionInfoContent
    const section = this.getCurrentSection();
    return this.getSectionPreambleContent(section);
  }

  getCurrentSectionPostambleContent() {
    const section = this.getCurrentSection();
    return this.getSectionPostambleContent(section);
  }

  hasPostambleContent() {
    return this.getCurrentSectionPostambleContent()?.length;
  }

  getSectionPostambleContent(section: ISectionDef) {
    if (section.postambleList) {
      return section.postambleList.map((postambleId) => this.getQuestionDef(postambleId))
    }
    return undefined;
  }

  getActiveQuestionContent() {
    const currItemId = this.getActiveQuestionId();
    const content = this.getQuestionDef(this.getActiveQuestionId());

    const itemContent = this.getQuestionContentBeforeAfter(currItemId, content);
    if (this.isFromItemSetEditor && itemContent) return itemContent;

    // console.log('content', content)
    return content;
  }

  getActiveReadingSelectionContent(){
    const currReadSelItemId = this.getActiveReadingSelectionId();
    const readSelContent = this.getQuestionContentBeforeAfter(currReadSelItemId, this.activeReadingSelection);
    if (this.isFromItemSetEditor && readSelContent) return readSelContent;

    return this.activeReadingSelection;
  }

  /**
   * Get the question config data depending on the Before or After view
   * @param qId the question id
   * @param defaultConfig the question config of the "before" edit view
   * @returns the question config data
   */
  getQuestionContentBeforeAfter(qId: number, defaultConfig: IQuestionConfig){
    if (qId){
      const suggestion = this.questionSuggestions.get(qId);
      if (suggestion){
        switch(this.getQuestionEditViewMode(qId)) {
          case EditViewMode.BEFORE:
            return defaultConfig;
          case EditViewMode.AFTER:
            return this.questionSuggestions.get(qId).state;
          default:
            return this.questionSuggestions.get(qId).state || defaultConfig;
        }
      }
    }
    return undefined;
  }

  getActiveReadSelContent() {
    return this.activeReadingSelection;
  }

  setActiveReadingSelection() {
    this.activeReadingSelection = undefined;
    const id = this.getActiveQuestionReadSel();
  }

  getReadingSelections() {
    const question = this.getQuestionDef(this.getActiveQuestionId());
    if (question){
      return question.readSelections;
    }
  }

  private getQuestionsByLabel(){
    const questionMap:Map<string, IQuestionConfig> = new Map()
    this.questionSrcDb.forEach((question: IQuestionConfig)=>{
      questionMap.set(question.label, question);
    });
    return questionMap
  }

  getCurrentZoomDisplay(){
    return Math.floor( 100*(this.getZoomLevel()/this.defaultZoomLevel) ) + '%';
  }

  getReadingSelectionCanvases() {
    const readers = [];
    const readSels = this.getReadingSelections();
    const questionMap = this.getQuestionsByLabel();
    
    if (readSels) {
      readSels.forEach((itemLabel)=>{
        const question = questionMap.get(itemLabel);
        if (question) {
          this.extractReadSelQuestionMeta(question, readers)
        }
      })
    }
    this.readerInfo = readers;
    // console.log('readerInfo', this.readerInfo)
    return readers;
  }

  private extractReadSelQuestionMeta(question: IQuestionConfig, readers: any[] ) {

    const trackReaderLink = (question:IQuestionConfig, canvasElement?:IContentElementCanvas) => {
      let caption, canvasId;
      if (canvasElement){
        caption = canvasElement.caption
        canvasId = canvasElement.readerId
      }
      if (!caption){
        caption = question.caption || question.label;
      }
      readers.push({
        canvasId, 
        readerId: question.label, 
        caption,
        readingSelectionCaption: question.readingSelectionCaption,
        itemLabel: question.label
      })
    }

    let isAtLeastOneCanvasFound = false;
    if (question.isReadingSelectionPage){
      question.content.forEach((element:IContentElement)=>{
        if (element.elementType == ElementType.CANVAS) {
          const canvasElement = <IContentElementCanvas> element;
          trackReaderLink(question, canvasElement);
          isAtLeastOneCanvasFound = true;
        }
      })
    }
    if (!isAtLeastOneCanvasFound) {
      trackReaderLink(question);
    }
  }

  getReadSelQueTextLinkEl(qId: number){
    const question = this.getQuestionDef(qId);
    const readers = [];
    this.extractReadSelQuestionMeta(question, readers);
    if(!readers.length) return undefined;
    return this.convertReadSelToTextLink(readers[0]);
  }

  sectionSplitScreenQuestions: Set<string>; 
  initSetctionSplitScreenQuestions = () => {
    const sectionQuestions = this.getCurrentQuestions();
    const splitScreenQuestions: Set<string> = new Set();
    const questionBylabelMap = this.getQuestionsByLabel();
    
    sectionQuestions?.forEach(qId => {
      const question = this.getQuestionDef(qId);
      if(question){
        const questions = question.readSelections?.map(label => label);
        questions?.forEach(label => {
          splitScreenQuestions.add(label); 
        });
      }
    });

    this.sectionSplitScreenQuestions = splitScreenQuestions;
  }

  hasSplitScreenLinkInNav = (qId: number) => {
    const qLabel = this.getQuestionLabel(qId);
    if(!qLabel) return false;
    return this.sectionSplitScreenQuestions?.has(qLabel);
  }

  hasReadingSelections() {
    const readSels = this.getReadingSelections();
    if (readSels && readSels.length>1) return true;
    return false;
  }

  isQuestionActiveInSplitScreen(qId: number) {
    if (!qId) return false;
    const val = this.hyperLinkService.linkRequest.getValue();
    const qLabel = this.getQuestionLabel(qId);
    return val.itemLabel === qLabel;
  }

  hasExactlyOneReadingSelection() {
    const readSels = this.getReadingSelections();
    if (readSels && readSels.length==1) {
      return true;
    }
    return false;
  }

  isReadingSelectionListToggle() {
    return this.getActiveQuestionContent()?.isReadingSelectionsAlwaysShown;
  }

  getActiveQuestionReadSel() {
    const readSelections = this.getReadingSelections();
    if (!readSelections) return undefined;
    readSelections.forEach((candReadSel)=>{
      this.questionSrcDb.forEach((question:IQuestionConfig, id:number) => {
        if (question.label===candReadSel) {
          if (question.label === this.itemLabel){
            this.testState.readSelItemId = id;
            this.activeReadingSelection = question;
          } else {
            question.content.forEach((element)=>{
              if (element.elementType==ElementType.CANVAS && element["readerId"]==this.currentReadSelection) {
                if (this.activeReadingSelection != question) {
                  this.activeReadingSelection = question;
                  this.testState.readSelItemId = id;
                }
              }
            })
          }
        }
      })
    })
    //console.log(readSel)
    if (this.activeReadingSelection) return this.activeReadingSelection.label;
    return undefined;
  }

  getCurrentQuestionId(){
    const qIndex = this.getCurrentQuestionIndex()
    return this.getCurrentQuestions()[qIndex];
  }

  isCurrentQuestionFilled(entryIds?:string[]) {
    return this.isQuestionFilled(this.getCurrentQuestionIndex(), entryIds);
  }

  isQuestionFlaggedSection(sIndex: number, qIndex: number) {

  }

  isQuestionFlagged(qIndex: number) {
    let qState = this.getQuestionStateByIndex(qIndex);
    if (qState){
      return qState.__meta.isFlagged;
    }
  }

  getQuestionStateEntries(qState:{[key:string]:any}){
    const entryIdsUnfiltered = Object.keys(qState);
    const entryIds = entryIdsUnfiltered.filter(str => str !== '__meta' );
    return entryIds.map(entryId => {
      qState[entryId].entryId = entryId
      return qState[entryId];
    })
  }

  isReadingSelection = (qId: number) => {
    return this.questionSrcDb.get(qId).isReadingSelectionPage;
  }

  // getQuestionTitleVoiceoverUrl = (sId: number, qId: number) => {
  //   const q = (<IQuestionConfig>this.questionSrcDb.get(qId));
  //   if(!q?.captionVoiceover) {
  //     return;
  //   }
  //   return q.captionVoiceover[sId]?.url;
  // }

  // getCurrentSectionQTitleUrl = (qId: number) => {
  //   return this.getQuestionTitleVoiceoverUrl(this.getCurrentSection()?.sectionId, qId);
  // }

  isQuestionFilled = (qIndex: number, entryIds?:string[]) => {
    let qState = this.getQuestionStateByIndex(qIndex);
    return this.isQuestionStateFilled(qState, entryIds);
  }

  isQuestionStateFilled(qState: any, entryIds?:string[]) {
    const hasSpecReqEntries = (entryIds && entryIds.length > 0);
    if (qState) {
      try {
        let isAllFilled = true;
        let numSpecFilled = 0;
        const entries = this.getQuestionStateEntries(qState);
        if (entries.length == 0 && !hasSpecReqEntries){
          return qState.__meta.isStarted; // this means that the state has been initialized (by the user accessing the item, but there is nothing to be filled)
        }
        entries.forEach(entry => {
          if (!entry.isFilled) {
            isAllFilled = false;
          }
          if (hasSpecReqEntries && (entryIds.indexOf(''+entry.entryId) !== -1)  ){
            if (entry.isFilled){
              numSpecFilled ++
            }
          }
        });
        if (hasSpecReqEntries && numSpecFilled < entryIds.length){
          return false;
        }
        return isAllFilled;
      } 
      catch (e) {
        return false;
      }
    }
    return false;
  }

  containsWideLoad() {
    const question = this.getActiveQuestionContent();
    let isMatch = false;
    if (question) {
      question.content.forEach( element => {
        if (element.elementType === ElementType.SBS) {
          isMatch = true;
        }
      });
    }
    return isMatch;
  }

  getQuestionStateByIndex(qIndex:number){
    const questions = (this.getCurrentQuestions() || []);
    const qId = questions[qIndex];
    return this.getQuestionState(qId);
  }

  getQuestionState(qId:number){
    const states = this.getCurrentQuestionStates();
    let qState = states[qId];
    if (!qState) {
      qState = states[qId] = {};
    }
    if (!qState.__meta){
      qState.__meta = {};
    }
    return qState;
  }
  getActiveQuestionState() {
    const qId = this.getActiveQuestionId();
    return this.getQuestionState(qId)
  }

  getActiveReadingSelectionId() {
    const rsId = this.testState.readSelItemId;
    return rsId;
  }

  getActiveReadingSelectionState() {
    const qId = this.getActiveReadingSelectionId();
    return this.getQuestionState(qId)
  }

  getReadingSelectionState() {
    const qId = this.getActiveReadingSelectionId()
    const state = this.getQuestionState(qId)
    return state;
  }

  getResultQuestionDef(questionId: number) {
    return <IQuestionConfig> this.resultQuestDefs.get(questionId)
  }

  getQuestionDef(questionId: number) {
    return <IQuestionConfig> this.questionSrcDb.get(questionId);
  }
  getQuestionLabel(questionId: number) {
    const question = this.getQuestionDef(questionId);
    if (question){
      return question.label;
    }
  }

  showCurrentQuestionLabel(){
    alert("Item Label for Lookup: " + this.getQuestionLabel(this.getActiveQuestionId()))
  }

  scrollQuestionIntoView() {
    // const el = this.questionDisplay.nativeElement;
    // el.scrollIntoView();
  }

  updatePosition(sectionIndex :number, questionIndex :number) {
    let differentSection = false;
    if (sectionIndex != this.testState.currentSectionIndex) {
      differentSection = true;
    }
    this.sectionIdDropdownSelection = sectionIndex;
    this.testState.currentSectionIndex = sectionIndex;
    this.testState.currentQuestionIndex = questionIndex;
    this.rightPageId = 0;
    this.leftPageId = 0
    const positionData = {
      stageIndex: this.testState.currentSectionIndex,
      questionCaption: this.getCurrentQuestionTitle(),
    }
    this.studentPosition.emit(positionData);
    if (differentSection) {
      this.setDefaultZwibblerContext();
      if(this.isSplitScreenLinkInNavBar()) this.initSetctionSplitScreenQuestions();
    }
  }

  selectSectionAndQuestion(sectionIndex, questionIndex) {
    this._saveQuestion().then(() => {
      if (sectionIndex !== this.testState.currentSectionIndex) {
        this.sectionTimeStarted = (new Date()).valueOf();
      }
      this.updatePosition(sectionIndex, questionIndex);
      this.scrollQuestionIntoView();
    });
  }

  setFillColor(color: string) {
    this.bgFillService.update(color);
  }

  getFillColor() {
    return this.bgFillService.getFillColor()
  }

  getColors(): string[] {
    return BACKGROUND_COLORS;
  }

  logDrawing() {
    this.drawLog.commitLogAndClear(this.testState, this.testSessionId);
  }

  getDrawingToolsSlug() {
    return 'btn_toggle_editor';
  }

  getFreehandSlug() {
    if(this.isABED() || this.isCAEC()){
      return 'abed_btn_freehand'
    }
    return 'btn_freehand';
  }

  getHighlighterSlug() {
    return 'el_draw_highlighter';
  }

  getEraserSlug(){
    return 'btn_eraser';
  }

  getFillSlug(){
    if (this.isABED() || this.whitelabel.isTestCenter()){
      return 'btn_background_color_abed';
    }
    return 'btn_background_color';
  }

  getCalcSlug() {
    return 'btn_calculator';
  }

  getDictionarySlug(){
    return 'btn_dictionary';
  }

  getLineReaderCaption(){
    if(this.isABED() || this.isCAEC()){
      return 'abed_btn_line_reader'
    }
    if(this.checkIsG9()) {
      return 'btn_line_reader_g9';
    }
    if (this.checkIsOsslt() || this.checkIsOssltTools() || this.isPj){
      return 'btn_line_reader_osslt';
    }
    else {
      return 'btn_line_reader';
    }
  }

  getNavDdownLabel(){
    return this.asmtFmrk.labelNavDdown || this.isABED() || this.isCAEC() ? this.lang.tra('abed_navigate_assessment') : this.lang.tra('btn_navigate_assessment');
  }

  clearReadingSelection() {
    this.activeReadingSelection = undefined;
    this.currentReadSelection = undefined;
    this.currentBookmark = undefined
    this.itemLabel = undefined;
    this.testState.currentReadingPassageId = undefined;
  }

  questionResetter = true;
  selectQuestion(questionIndex) {
    this.hyperLinkService.clearBookmarks.next({});
    this.questionResetter = false;
    this.changeDetector.detectChanges();
    this.restartQuestionResaveInterval();
    this.selectPageMode(this.pageModeFlow.length - 1); //index of the TEST_RUNNER page mode
    if(questionIndex < 0) {
      return;
    }
    this.hideSectionInfo();
    this.hidePostambleInfo();
    this.isHelpOverlay = false;
    // document.getElementById("quest-cont").style.display = "flex"
    if(!this.showDeactivatedMode && this.isShowOverlay){
      this.disableOverlay()
    }

    const updateQuestionIndex = () => {
      this.updatePosition(this.testState.currentSectionIndex, questionIndex);
      this.testState.currentQuestionIndex = questionIndex;
      this.markActiveQuestionAsStarted();
      // try { this.closePassage() } catch (e) {}
      this.reinitReadingSelection()
      this.clearTools(true);
      this.lastFillState = this.isQuestionFilled(questionIndex);
      this.scrollToQuestion();
    }
    if(this.isShowingResults) {
      updateQuestionIndex();
      this.questionResetter = true;
      this.changeDetector.detectChanges();
    } 
    else {

      this._saveQuestion()
      .then(() => {        
        updateQuestionIndex()
        this.questionResetter = true;
        this.changeDetector.detectChanges();
      })
      .then(() => this._logItemView())
      .then(() => this._logAssetView())
      .then(() => {
        if (this.isFromItemSetEditor) this.getQuestionSuggestions(this.getActiveQuestionId())
      })
    }
    this.questionResetter = true;
    this.changeDetector.detectChanges();
    this.restartQuestionResaveInterval();
  }
  
  currentReadSelDisplayMode:ReaderTextMode;
  reinitReadingSelection(){
    const lastReadSelId = this.currentReadSelection;
    const lastReadSelItemLabel = this.itemLabel;
    // this.hyperLinkService.linkRequest.next({
    //   readerElementId: undefined,
    //   readerId: undefined,
    // });
    this.getReadingSelectionCanvases();
    const nextReadSel = this.readerInfo ? this.readerInfo[0] : null;
    const isSameReadingSelection = nextReadSel && ((nextReadSel.canvasId && lastReadSelId === nextReadSel.canvasId) || (nextReadSel.readerId && lastReadSelId === nextReadSel.readerId) || (nextReadSel.itemLabel && lastReadSelItemLabel === nextReadSel.itemLabel))
    if ( !nextReadSel || !isSameReadingSelection){
      this.clearReadingSelection();
      this.isShowingReadingSelections = false;
      this.currentReadSelDisplayMode = ReaderTextMode.CLOSED;
      this.hyperLinkService.linkRequest.next({})
      this.resetFlag();
    }
    if (nextReadSel && this.getActiveQuestionContent().isStartHalf){
      this.currentReadSelDisplayMode = ReaderTextMode.HALF;
      if (!isSameReadingSelection){
        this.openDefaultTextLink();
        this.resetFlag();
      }
    }
    else if (isSameReadingSelection){
      this.currentReadSelDisplayMode = ReaderTextMode.HALF;
    }

    if (isSameReadingSelection){
      this.canvasService.bump()
    }
    this.onResize();
  }
  readSelViewFull(){
    this.currentReadSelDisplayMode = ReaderTextMode.FULL;
    this.onResize();
  }
  readSelViewHalf(){
    this.currentReadSelDisplayMode = ReaderTextMode.HALF;
    this.onResize();
  }

  readSelViewClose(){
    this.currentReadSelDisplayMode = ReaderTextMode.CLOSED;
    if(!this.isShowCutsomSplitScreenControls()) this.hyperLinkService.linkRequest.next({});
    this.onResize();
  }

  isShowingLeft(){
    if(this.isShowingResults) {
      return true;
    }
    if (this.getActiveQuestionReadSel()){
      switch(this.currentReadSelDisplayMode){
        case ReaderTextMode.FULL:
            return false;
        case ReaderTextMode.HALF:
        case ReaderTextMode.CLOSED:
            return true;
      }
    }
    return true;
  }
  isShowingRight(){
    if (this.getActiveQuestionReadSel() || (this.asmtFmrk.showDocumentsSplitScreen && this.isShowDocuments) || this.isCalcToggledOn || this.isDictionaryToggledOn){
      switch(this.currentReadSelDisplayMode){
        case ReaderTextMode.CLOSED:
          return false;
        case ReaderTextMode.FULL:
        case ReaderTextMode.HALF:
            return true;
      }
    }
    return false;
  }

  isShowingSplitScreen() {
    return this.isShowingLeft() && this.isShowingRight();
  }

  isShowSplitScreenControlBtn() {
    const show = this.getActiveQuestionReadSel() && !this.isShowingResults;
    if(this.isPj) return show && this.isShowingRight();
    return show;
  }

  toggleInfoModal() {
    this.loginGuard.toggleInfoOverlay()
  }

  questionSuggestions = new Map<number, QuestionSuggestion>();
  getQuestionSuggestions(questionId: number){
    this.auth.apiFind(this.routes.TEST_AUTH_SUGGESTIONS, {query: {
      lang: this.lang.c(),
      question_id: questionId
    }}).then((res) => {
      if(res?.length) {
        const s = res[0].suggestion;

        const changes = s.changes || '{}';
        const annotations = s.annotations || '{}';

        const suggestion = {
          id: s.id,
          versionId: s.version_id,
          state: JSON.parse(s.config),
          changes: JSON.parse(changes),
          annotations: JSON.parse(annotations)
        }

        this.questionSuggestions.set(questionId, suggestion);
        this.setQuestionEditViewMode(questionId, EditViewMode.AFTER);
      }
    }).catch((e) => {
      console.error('Error loading question suggestion: ', e)
    });
  }

  questionEditViewMode = new Map<number, EditViewMode>()
  setQuestionEditViewMode(qId: number, viewMode: EditViewMode){
    if (!this.isViewModeSelectionDragging){
      this.questionEditViewMode.set(qId, viewMode);
    } else {
      this.isViewModeSelectionDragging = false;
    }
  }

  isViewModeSelectionDragging: boolean = false;
  onViewModeSelectorDragEnd(){
    this.isViewModeSelectionDragging = true;
  }

  getQuestionEditViewMode(qId: number){
    return this.questionEditViewMode.get(qId);
  }
  
  isShowReadSelLinkInNavbar(qId: number) {
    const question = this.getQuestionDef(qId);
    return this.readerInfo?.some(readerInfo => readerInfo.itemLabel === question.label);
  }

  private scrollElsToByClass = (ids:string, isToBott:boolean=false, positionY?:number) => {
    const elements = document.getElementsByClassName(ids);
    if (elements){
      for (let i=0; i<=elements.length; i++){
        const el = elements[i];
        if (el){
          if (positionY === undefined){
            if (isToBott){
              positionY = el.scrollHeight;
            }
            else {
              positionY = 0;
            }
          }
          el.scrollTo(0, positionY)
        }
      }
    }
  }

  questionNavScrollToEnd(){
    this.scrollElsToByClass('question-navbar-container', true);
  }
  questionNavScrollToStart(){
    this.scrollElsToByClass('question-navbar-container', false);
  }
  questionNavScrollToQuestion(){
    // this.scrollElsToByClass('test-questions', false);
    try {
      const containerEl = document.getElementsByClassName('question-navbar-container')[0];
      const targetEl = document.getElementsByClassName('question-navbar-block is-active')[0];
      if (containerEl && targetEl){
        var containerRect = containerEl.getBoundingClientRect();
        var targetRect = targetEl.getBoundingClientRect();
        var top = targetRect.top - containerRect.top;
        var scrollTarget = containerEl.scrollTop;
        var isReqScrollDown = (top + targetRect.height) > containerRect.height;
        var isReqScrollUp = top  < 0;
        // console.log('scroll', {
        //   scrollTarget,
        //   y: top,
        //   y2: top + targetRect.height,
        //   h: containerRect.height,
        //   isReqScrollDown, 
        //   isReqScrollUp,
        // })
        if (isReqScrollDown){
          scrollTarget += top - (containerRect.height - targetRect.height)
        }
        if (isReqScrollUp){
          scrollTarget += top
        }
        // containerEl.scrollTo(0, scrollTarget)
        

        containerEl.scrollTo({
          top: scrollTarget,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
    catch(e){}
  }

  scrollNavToEnd = () => {
    try {
      const containerEl = document.getElementsByClassName('question-navbar-container')[0];
      if (!containerEl) return;
      const scrollHeight = containerEl.scrollHeight;
      const height = containerEl.getBoundingClientRect().height;
      containerEl.scrollTo(height,scrollHeight)
    } catch (error) {}    
  }

  scrollToQuestion(){
    // content
    window.scrollTo(0, 0);
    this.scrollElsToByClass('split-view-left');
    // this.scrollElsToByClass('split-view-right');
    this.scrollElsToByClass('helper-tools');
    // sidebar
    setTimeout(() => this.questionNavScrollToQuestion(), 100)
  }

  openDefaultTextLink(){
    if (this.readerInfo?.length){
      const readSel = this.readerInfo[0];
      this.hyperLinkService.linkRequest.next({
        readerElementId: undefined,
        readerId: readSel.readerId,
        itemLabel: readSel.itemLabel
      })
      this.onResize();
      return readSel;
    }
  }



  markActiveQuestionAsStarted(){
    const qState = this.getActiveQuestionState();
    qState.__meta.isStarted = true;
  }

  clearTools(keepDocuments: boolean = false){
    this.isFormulasToggledOn = false;
    this.isCalcToggledOn = false;
    this.isDictionaryToggledOn = false;

    if(!keepDocuments) {
      this.isShowDocuments = false;
      this.clearReadingSelection();
    }
    
    this.isHelpOverlay = false;
  }

  gotoPrev() {
    if(this.isShowingResults && this.pageModeIndex > 0 && this.getCurrentQuestionIndex() === 0) {
      this.gotoPrevPageMode();
      return;
    }
    if (this.blockMoveAwayFromReqQuestion()){
      return;
    }
    
    if(this.isShowingPostambleInfo) {
      if(this.postambleIndex !== 0) {
        this.postambleIndex--;
      } 
      return;
    }
    
    if (this.getCurrentQuestionIndex() <= 0){
      if(!this.isShowingSectionInfo && this.hasPreambleContent()) {
        this.preambleIndex = this.getCurrentSectionPreambleContent().length - 1;
        this.showSectionInfo();
        return;
      }     

      if(this.isShowingSectionInfo && this.preambleIndex > 0) {
        this.preambleIndex--;
        return;
      }
      this.gotoPrevSection()
      return
    }
    this.gotoPrevQuestion()
  }

  selectPageMode(index: number) {
    this.pageModeIndex = index;
    this.pageMode = this.pageModeFlow[index].slug;
  }

  gotoPrevPageMode() {
    this.selectPageMode(this.pageModeIndex - 1);
  }

  gotoNextPageMode() {
    this.selectPageMode(this.pageModeIndex + 1);
    if(this.pageModeIndex === this.pageModeFlow.length - 1){
      this.selectQuestion(0);
    }
  }

  blockMoveAwayFromReqQuestion(){
    if(!this.isShowingResults) {
      const question = this.getActiveQuestionContent();
      // console.log('blockMoveAwayFromReqQuestion', question.isReqFill)
      if (question && question.isReqFill){
        let entryIds = [];
        if (question.reqFillEntries){
          entryIds = question.reqFillEntries.split(',').map(str => str.trim())
        }
        if (!this.isCurrentQuestionFilled(entryIds)){
          this.loginGuard.quickPopup(question.reqFillMsg);
          return true;
        }
      }
    }
    return false;
  }

  showPrevNext(){
    if(this.useCustomNextPrev()) {
      return false;
    }

    if (this.isShowingResults && (this.pageMode !== PageMode.TEST_RUNNER)){
      if (this.asmtFmrk && this.asmtFmrk.isResultsDetailDisabled){
        return false;
      }
    }
    return true;
  }

  isResultsDisabled(){
    return this.asmtFmrk.isResultsDetailDisabled || this.checkTag('RESULTS_DISABLED')
  }

  getPrevBtnSlug() {
    if(this.isABED() || this.isCAEC()){
      return "abed_back"
    }
    if(this.isEQAO()) {
      return 'btn_prev_eqao';
    }
    return 'btn_prev_bc';
  }

  getNextBtnSlug() {
    if(this.isABED() || this.isCAEC()){
      return "btn_next_abed";
    }
    if(this.isEQAO()) {
      return 'btn_next_eqao';
    }
    return 'btn_next_bc';
  }

  showQHeader() {
    if (this.checkTag('QUESTION_HEADER_DISABLED')){
      return false;
    }
    return this.isFlushNavigation() || this.asmtFmrk.showQHeader;
  }

  isPassagePadded(){
    return this.checkTag('PASSAGE_PADDED')
  }

  getHelpSlug() {
    return this.isPj ? 'btn_pj_help' : 'btn_help';
  }

  getListenSlug() {
    return 'btn_listen';
  }

  async gotoNext() {
    if(this.isShowingResults && this.pageModeIndex < this.pageModeFlow.length - 1) {
      return this.gotoNextPageMode();
    }
    if (this.isHelpOverlay){
      return this.isHelpOverlay = false;
    }
    if(this.isShowingPostambleInfo) {
      if(this.postambleIndex === this.getCurrentSectionPostambleContent().length - 1) {
        this.postambleCallback().then(() => {
          this.hidePostambleInfo();
        });
      } else {
        this.postambleIndex++;
      }
      return;
    }
    if (this.isShowingSectionInfo){
      if(this.preambleIndex === this.getCurrentSectionPreambleContent().length - 1) {
        if(this.getCurrentQuestions().length == 0){
          this.hideSectionInfo()  
          return this.reviewAndSubmit()
        }
        return this.hideSectionInfo()
      } else {
        this.preambleIndex++;
        return;
      }
    }

    if(!this.isShowingResults) {
      if (this.blockMoveAwayFromReqQuestion()){
        return;
      }
    }

    if (this.isOnLastQuestion()){
      this.scrollNavToEnd()
      if(!this.isShowingResults) {
        return this.reviewAndSubmit();
      } 
      else {
        return this.leaveResults();
      }
    }
    this.gotoNextQuestion()
  }

  getPjNextBtnSlug() {
    return this.isOnLastQuestion() ? 'btn_submit' : 'pj_next_btn';
  }

  gotoNextQuestion() {
    this.selectQuestion(this.testState.currentQuestionIndex + 1);
  }

  gotoPrevQuestion() {
    this.selectQuestion(this.testState.currentQuestionIndex - 1);
  }

  isOnLastQuestion() {
    return this.testState.currentQuestionIndex >= this.getCurrentQuestions().length - 1;
  }

  countNumCurrentQuestionsUnfilled() {
    let numUnfilled = 0;
    let questionIds = this.getCurrentQuestions();
    if (!questionIds) {
      console.error('no questions');
      return 0;
    }
    questionIds.forEach((qId, qIndex) => {
      if (!this.isQuestionFilled(qIndex) && !this.questionSrcDb.get(qId).isReadingSelectionPage) {
        numUnfilled ++;
      }
    });
    return numUnfilled;
  }

  countNumberofFlaggedQuestions = () => {
    const flaggedQuestions = this.returnArrayOfFlaggedQuestions();
    return flaggedQuestions.length;
  }

  returnArrayOfUnfilledQuestions = () => {
    let array = [];
    let questionIds = this.getCurrentQuestions();
    if (!questionIds) {
      console.error('no questions');
      return [];
    }
    questionIds.forEach((qId, qIndex) => {
      if (!this.isQuestionFilled(qIndex)) {
        array.push(this.getQuestionTitle(qIndex));
      }
    });
    return array;
  }

  returnArrayOfFlaggedQuestions = () => {
    let flaggedQuestion = []
    this.getCurrentQuestions().forEach((qId, i) => {
      const qState = this.getQuestionState(qId);
      if (qState.__meta.isFlagged){
        flaggedQuestion.push(i)
      }
    })
    return flaggedQuestion;
  }

  private confirmAndSubmitTest(){
    this.screenUnfreeze.emit()
    if (this.checkTag(KNOWN_TEST_RUNNER_TAGS.NO_SUBMIT_CONF)){
      this._submitTest(); // not the cause of gap
      return;
    }
    const {numUnfilled, numFlagged} = this.getFilledAndFlagged();
    
    const {dialogText, confirmConfig, cancelConfig} = this.confirmCustomPopups()

    this.activateModal(dialogText, () => {
      this._saveQuestion()
      .then(() => {
        this.emitSectionEndData({numUnfilled, numFlagged});
        this._submitTest();
      })
    }, confirmConfig, cancelConfig );
  }

  confirmCustomPopups(){
    try {
      // const arrayOfFlaggedQuestions = this.returnArrayOfFlaggedQuestions();
      // const arrayOfUnfilledQuestions = this.returnArrayOfUnfilledQuestions();
      // const numFlagged = arrayOfFlaggedQuestions.length;
      // const numUnfilled = arrayOfUnfilledQuestions.length;
      const {preMessage} = this.getSubmitPreMessage()
  
      let dialogText: string;
      let confirmBtnMsg: string;
      let cancelBtnSlugs: string[];
      const customDlg = this.customConfirmTestDialogData; 
      if (customDlg) {
        dialogText = this.lang.tra(customDlg.text);
        confirmBtnMsg = customDlg.confirmMsg;
        cancelBtnSlugs = [customDlg.cancelMsg];
      } 
      else {
        dialogText = preMessage + this.lang.tra(this.getAlertKKSubmitTestSlug());
        confirmBtnMsg = this.goBackAndReviewYesNavigation(true);
        cancelBtnSlugs = this.goBackAndReviewNoSlugs();
      }
  
      if (this.isFlushNavigation()){
        if (this.whitelabel.isABED() || this.whitelabel.isTestCenter()){
          const defaultFinalPopupDialogFromSP = this.styleProfile.getSectionPopup('default_final_submit_popup'); 
          if (defaultFinalPopupDialogFromSP){
            // Only overwrite if the corresponding customization is present in the styleprofile.
            if (defaultFinalPopupDialogFromSP.text)
              dialogText = defaultFinalPopupDialogFromSP.text;
            if (defaultFinalPopupDialogFromSP.nextBtnText)
              confirmBtnMsg = defaultFinalPopupDialogFromSP.nextBtnText;
            if (defaultFinalPopupDialogFromSP.backBtnText)
              cancelBtnSlugs = [defaultFinalPopupDialogFromSP.backBtnText];
          } else {
            dialogText = 'abed_alert_bc_section_submit_final';
          }
        } else {
          dialogText = this.lang.tra('alert_bc_section_submit_final');
        }
        // dialogText = this.whitelabel.isABED() ? this.lang.tra('abed_alert_bc_section_submit_final') : this.lang.tra('alert_bc_section_submit_final'); 
      }
      if (this.asmtFmrk && this.asmtFmrk.msgFinalSubmission){
        dialogText = this.asmtFmrk.msgFinalSubmission;
      }
  
      let customPopupSlug = this.asmtFmrk.partitions[this.asmtFmrk.partitions.length - 1].customSectionPopupSlug;
      let customSectionPopup = this.styleProfile.getSectionPopup(customPopupSlug, true); 
      if (this.isFlushNavigation() && customSectionPopup ){
          dialogText = customSectionPopup.text;
          confirmBtnMsg = customSectionPopup.nextBtnText;
          cancelBtnSlugs = [customSectionPopup.backBtnText]
      }
  
      const confirmConfig:IConfirmationReqBtnConfig = {caption: confirmBtnMsg};
      const cancelConfig:IConfirmationReqBtnConfig = {captions: cancelBtnSlugs};
  
      this.applyPjModalBtnColor(confirmConfig, cancelConfig);
  
      return {dialogText, confirmConfig, cancelConfig}
    }
    catch (e){
      return {
        dialogText: 'Submission?',
        confirmConfig: {caption: 'Confirm'},
        cancelConfig: {captions: ['Cancel']},
      }
    }
  }

  applyPjModalBtnColor(confirmConfig: IConfirmationReqBtnConfig, cancelConfig: IConfirmationReqBtnConfig) {
    if(this.isPj) {
      cancelConfig.bgColor = '#f7d83b';
      confirmConfig.bgColor = this.isG6 ? G6_SUBMIT_BG_COLOR : G3_SUBMIT_BG_COLOR;
      confirmConfig.fgColor = '#000000';
    }
  }

  private async _submitTest(){
    if(this.isFlushNavigation()) {
      await this.scoreAllQuestions();
    }
    this.submitTest(!!this.hasPostambleContent()).catch((e) => {
      handleSubmitTestErr(e, this.loginGuard, this.lang);
    }).then(() => {
      if(this.hasPostambleContent()) {
        this.showPostambleInfo(this.postSubmit);
        this.postambleIndex = 0;
      }
    });

    if(this.isFlushNavigation()) {
      this.scrollToQuestion()
    } 
  }

  isAnyFlaggedQuestions(){
    return this.countNumberofFlaggedQuestions() > 0;
  }

  isFlaggingEnabled(){
    const section = this.getCurrentSection();
    if (this.checkTag(KNOWN_TEST_RUNNER_TAGS.NO_FLAGGING)){
      return false
    }
    if (section){
      return !section.disableFlagging;
    }
  }

  isLeftBarDisabled(){
    if (this.checkTag('LEFT_BAR_DISABLED')){
      return true;
    }
    if(this.isLineReaderActive || this.isPj) {
      return true;
    }
    if (!this.isShowingResults){

      // todo: might need to add a flag for show results
      const section = this.getCurrentSection();
      
      if(this.inAmbleList()) {
        return true;
      }

      if (section){
        return section.disableLeftBar;
      }
    }
    return false;
  }

  isBackButtonDisabled() {
    const section = this.getCurrentSection();
    const sectionSettings = this.currentSectionSettings
    if(section || sectionSettings) {
      return section?.disableBackButton || sectionSettings?.disableBackButton;
    }

    return false;
  }

  isPauseOnSubmit() {
    const section = this.getCurrentSection();
    const sectionSettings = this.currentSectionSettings
    if(section || sectionSettings) {
      return section?.isPauseOnSubm || sectionSettings?.isPauseOnSubm;
    }

    return false;
  }

  getSplitViewStyle() {
    const currQ = this.getActiveQuestionContent();

    if(currQ?.backgroundImage?.url) {
      return {
        'background-image': `url(${currQ?.backgroundImage.url})`,
        'background-size': 'cover'
      }
    }
    return {};
  }

  usingPreambleList() {
    const section = this.getCurrentSection();
    return section.preambleList?.length;
  }

  isFullWidthThumbnail() {
    return this.asmtFmrk.isThumbnailFullWidth;
  }

  getSidebarThumbnail() {
    const section = this.getCurrentSection(); 
    switch(this.lang.c()) {
      case 'en':
        if(section?.sidebarThumbnailEn) {
          return section.sidebarThumbnailEn;
        }
        return this.asmtFmrk.sidebarThumbnailEn;
      case 'fr':
        if(section?.sidebarThumbnailFr) {
          return section.sidebarThumbnailFr;
        }
        return this.asmtFmrk.sidebarThumbnailFr;
    }
  }

  inAmbleList() {
    return (this.isShowingSectionInfo && this.usingPreambleList()) || this.isShowingPostambleInfo;
  }


  isFlagEnabledForCurrentQuestion(){
    if (this.isFlaggingEnabled()){
      const q = this.getActiveQuestionContent();
      if (q){
        return !(q.isReadingSelectionPage);
      }
    }
  }

  isCurrentQuestionFlagged(){
    const qState = this.getActiveQuestionState()
    return qState.__meta.isFlagged;
  }

  getFilledAndFlagged(){
    return {
      numUnfilled: this.countNumCurrentQuestionsUnfilled(),
      numFlagged: this.countNumberofFlaggedQuestions()
    }
  }

  isQuestionCorrect(id: number) {
    return this.questionScores.get(id) === QuestionScore.CORRECT;
  }

  isQuestionUnmarked(id: number) {
    return this.questionScores.get(id) === QuestionScore.UNMARKED;
  }

  getPrintElement(id: number) {
    const quest = this.getResultQuestionDef(id)
    let element:IContentElement = undefined
    if (quest) {
      quest.content.forEach((el)=>{
        if (el.elementType === ElementType.RESULTS_PRINT) {
          element = el
        }
      })
    }
    return element
  }

  getSubmitPreMessage(){
    const {numUnfilled, numFlagged} = this.getFilledAndFlagged();
    let preMessage = '';
    let cancelSlugs = this.goBackAndReviewNoSlugs();
    let sectionPopup = this.styleProfile.getSectionPopup(this.currentSectionSettings.customSectionPopupSlug); 
    if (this.isFlushNavigation()){
      let popupText = this.whitelabel.isABED() || this.whitelabel.isTestCenter() ? 'abed_alert_bc_section_submit' : 'alert_bc_section_submit';

      if (this.currentSectionSettings.customSectionPopup && sectionPopup?.text){
        popupText = sectionPopup?.text;
      }

      preMessage += this.lang.tra(popupText , undefined, {numUnfilled, numFlagged}) + ' ';
    } else if(this.checkIsOssltTools()) {
      cancelSlugs = ['osslt_tools_message']
    } else {
      if (this.checkIsOsslt()) {
        cancelSlugs = this.goBackAndReviewNoSlugs()
      } 
      const isAnyUnfilled = numUnfilled > 0;
      const isAnyFlagged = numFlagged > 0;
      const msgUnfilledAndFlagged = this.lang.tra('alert_UNFILLED_WARN_P1') + ' '+ numUnfilled + " question(s) " + this.lang.tra('osslt_flagged') + ' ' + numFlagged + ' ' + this.lang.tra(this.getAlertUnfilledWarnP2Slug(), undefined, {QUESTION_WORD: this.lang.tra(this.getQuestionWordSlug()).toLowerCase()}) + ' ';
      const msgUnfilledAndNotFlagged = this.lang.tra(this.getAlertNumUnfilledSlug(), undefined, {questionNum: numUnfilled});
      // const msgFilledAndFlagged = this.lang.tra('osslt_flagged_simple') + ' ' + numFlagged + ' '+ this.lang.tra(this.getAlertUnfilledWarnP2Slug(), undefined, {QUESTION_WORD: this.lang.tra(this.getQuestionWordSlug()).toLowerCase()}) + ' ';;
      if (isAnyUnfilled) {
        if (isAnyFlagged){
          preMessage += msgUnfilledAndFlagged;
        }
        else{
          preMessage += msgUnfilledAndNotFlagged;
        }
      } 
      else if (numUnfilled > 0 && numFlagged === 0) {
        preMessage += this.lang.tra('alert_UNFILLED_WARN_P1')+ ' ' + numUnfilled + " " + this.lang.tra(this.getAlertUnfilledWarnP2Slug(), undefined, {QUESTION_WORD: this.lang.tra(this.getQuestionWordSlug()).toLowerCase()}) + ' ';      } 
      else if (numUnfilled === 0 && numFlagged > 0) {
        preMessage += this.lang.tra('osslt_flagged_simple') + ' ' + numFlagged + ' '+ this.lang.tra(this.getAlertUnfilledWarnP2Slug(), undefined, {QUESTION_WORD: this.lang.tra(this.getQuestionWordSlug()).toLowerCase()}) + ' ';
      }
      else if(!isAnyUnfilled && this.isPj){
        if(this.checkTag(KNOWN_TEST_RUNNER_TAGS.PJ_LANG)) {
          preMessage=this.lang.tra('alert_pj_submit_session');
        }
        else if(this.checkTag(KNOWN_TEST_RUNNER_TAGS.PJ_MATH)) {          
          preMessage=this.lang.tra('alert_pj_math_submit_stage');
        }
      }
    }
    return {preMessage, cancelSlugs};
  }

  checkForUnfilledRequiredSectionQuestions(){
    let missingRequiredQuestion = false;
    this.getCurrentQuestions().forEach((qId, qIndex) => {
      const question = this.getQuestionDef(qId);
      if (question.isReqFill && !this.isQuestionFilled(qIndex)){
        missingRequiredQuestion = true;
        // console.log('missingRequiredQuestion', qId, question)
      }
    });
    return missingRequiredQuestion;
  }

  async getSectionData(): Promise<ISectionData> {
    // console.log(this.testAttemptId, 'attempt ID');
    // testAttemptId will be undefined when test runner is ran by the auth preview
    if (this.testAttemptId){
      const testAttemptSecData = await this.auth.apiGet(this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT_SECTION_DATA, this.testAttemptId);
      return testAttemptSecData[0];
    }

    return undefined;
  }

  async updateSectionMeta(id: number, data: Object, params?: any) {
    return await this.auth.apiPatch(this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT_SECTION_DATA, id, data, params);
  }

  async onSectionPause() {
    return await this.auth.apiCreate(this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT_SECTION_DATA, {attempt_id: this.testAttemptId, is_paused: 1})
  }

  /**
   * 3 cases
   * - user goes in and tries to submit, isUnpausedByInvig doesn't exist, attempt is paused.
   * - user goes in and tries to submit, isUnpausedByInvig exists but attempt is paused, section submit is not allowed
   * - user goes in and tries to submit, isUnpausedByInvig exists and attempt has been unpaused, section submit is allowed.
   */
  async isSectionUnpaused(): Promise<boolean> {
    const sectionDataRaw = await this.getSectionData();
    if (sectionDataRaw && sectionDataRaw.sections_meta){
      const sectionData: any = JSON.parse(sectionDataRaw.sections_meta);
      const section = this.getCurrentSection();
  
      // If the section data has never been initialized, it is safe to assume this is their first time pausing.
      if(!sectionData.hasOwnProperty(`${section.sectionId}`) || !sectionData[`${section.sectionId}`].hasOwnProperty('isPausedOnSubmit')) {
        if(!sectionData.hasOwnProperty(`${section.sectionId}`)) {
          sectionData[`${section.sectionId}`] = {
            isPausedOnSubmit: true
          }
        } else if(!sectionData[`${section.sectionId}`].hasOwnProperty('isPausedOnSubmit')) {
          sectionData[`${section.sectionId}`].isPausedOnSubmit = true;
        }
        await this.updateSectionMeta(sectionDataRaw.id, sectionData)
        await this.onSectionPause();
        return false;
      }
    }
    return true;
  }

  async reviewAndSubmit() {
    this.overlayState.deactivate = true;
    this.isNotepadEnabled = false;
    this.showDeactivatedMode = true;
    if (this.checkForUnfilledRequiredSectionQuestions()){
      let message = 'lbl_questions_req_unfilled'
      const section = this.getCurrentSection();
      if (section.msgReqFill){
        message = section.msgReqFill
      }
      this.loginGuard.quickPopup(this.lang.tra(message))
      return;
    }
    console.log('reviewAndSubmit')
    this._saveQuestion().then(async () => {
      if(this.isPauseOnSubmit()) {
        const sectionUnpaused = await this.isSectionUnpaused();
        if(!sectionUnpaused) {
          this.loginGuard.confirmationReqActivate({
            caption: this.lang.tra('msg_session_pause'),
            btnCancelConfig: {
              hide: true
            }
          });
          console.log('Has not been unpaused by the invigilator. Returning.');
          return;
        }
      }
      // console.log('onLastSection', this.onLastSection(), this.testRunnerSections.length, this.testState.currentSectionIndex );
      if (this.onLastSection() && !this.checkIsOsslt()) {
        this.confirmAndSubmitTest();
      }
      else {
        this.confirmAndSubmitSection(); // checks need
      }
    }).catch((e) => {
      console.log(e);
    })
  }

  leaveResults() {
    this.activateModal(this.lang.tra("tr_results_exit"), () => {
      return this.exitResults();
    }, {caption: 'lbl_yes'}, {caption: 'lbl_no'});
  }

  onLastSection() {
    if(this.sectionsAllowed && !this.isBCED()) {
      return this.testState.currentSectionIndex === this.sectionsAllowed[this.sectionsAllowed.length - 1];
    }
    return this.testState.currentSectionIndex >= this.testRunnerSections.length - 1
  }

  private getSubmitSectionEmitMsg(){
    if (this.isFlushNavigation() || this.isPj){
      return '';
    }
    else if (this.checkIsOsslt() && this.onLastSection()) {
      return this.lang.tra('osslt_confirm_submit_test');
    } 
    else if (this.checkIsOsslt()) {
      return this.lang.tra('osslt_confirm_submit')
    }
    else {
      return this.lang.tra(this.getAlertKKSubmitSectionSlug())
    }
  }

  private emitSectionEndData(context: {numUnfilled:number, numFlagged:number}){
    const {numUnfilled, numFlagged} = context;
    sessionStorage.setItem("totalFilledBySection", JSON.stringify(this.totalFilledBySection));
    
    let data;
    if (numUnfilled > 0 && numFlagged > 0) {
      data = {hasUnfilled: true, hasFlags: true, arrayOfFlaggedQuestions: this.returnArrayOfFlaggedQuestions(), arrayOfUnfilledQuestions: this.returnArrayOfUnfilledQuestions()};
    } else if (numUnfilled > 0 && numFlagged === 0) {
      data = {hasUnfilled: true, hasFlags: false, arrayOfUnfilledQuestions: this.returnArrayOfUnfilledQuestions()}
    } else if (numUnfilled === 0 && numFlagged > 0) {
      data = {hasUnfilled: false, hasFlags: true, arrayOfFlaggedQuestions: this.returnArrayOfFlaggedQuestions()}
    } else {
      data = {hasUnfilled: false, hasFlags: false}
    }

    this.endSection.emit( { 
      sectionIndex: this.testState.currentSectionIndex,
      ...data
    });
  }

  private allowNextSectionNav(){
    if (this.checkIsOsslt() && !this.isPreview){
      return false;
    }
    if (this.checkIsOssltTools() && !this.isPreview){
      return false;
    }
    return true;
  }

  private async confirmAndSubmitSection(){
    const {numUnfilled, numFlagged} = this.getFilledAndFlagged();
    const {preMessage, cancelSlugs} = this.getSubmitPreMessage();
    const proceed = () => {
      if(this.isPj && this.hasPostambleContent()) {
        //emitting section end data may bring you back to map for PJ
        //We want to show any post-amble first
        if(this.hasPostambleContent()) {
            this.showPostambleInfo(
              ()=>{
                this.emitSectionEndData({numUnfilled, numFlagged}); 
                this.gotoNextSection();
                return Promise.resolve()
              }
            ); 
          return;
        } 
      } else {
        this.emitSectionEndData({numUnfilled, numFlagged});
      }
      if( this.allowNextSectionNav() ) {
        this.gotoNextSection();
      }
    }
    
    // Fails when the current section is a part of Choosen path: maybe use this.getNextSectionIdxWhenConditional(currentSectionIdx)
    const nextSectionIndex = this.getCurrentSectionIndex() + 1;
    await this.ensureSectionPathFill(nextSectionIndex);

    if (this.checkTag(KNOWN_TEST_RUNNER_TAGS.NO_SUBMIT_CONF) || (this.isFlushNavigation() && (numUnfilled === 0) && (numFlagged===0))){
      proceed();
    }
    else{
      let navCollapsed = true
      if (!this.isTestNavExpanded) {
        navCollapsed = false
        this.isTestNavExpanded = true;
      }

      const confirmConfig = {caption: this.goBackAndReviewYesNavigation()};
      const cancelConfig = {captions: cancelSlugs};
      this.applyPjModalBtnColor(confirmConfig, cancelConfig);
      this.activateModal( preMessage + this.getSubmitSectionEmitMsg(), () => {
        this._saveQuestion().then(() => {
          proceed();
          if (!navCollapsed) {
            this.isTestNavExpanded = false;
          }
        });
      }, confirmConfig, cancelConfig );
      
    }
  }

  private gotoPrevSection(){
    if (this.testState.currentSectionIndex > 0 && this.isFlushNavigation()){
      this._saveQuestion().then(()=>{
        this.clearTools();
        const nextSectionIndex = this.testState.currentSectionIndex - 1;
        const nextQuestions = this.testRunnerSections[nextSectionIndex].questions;
        this.updatePosition(nextSectionIndex, nextQuestions.length - 1);
        this.hideSectionInfo();
        this.initLastQuestion()
        this.questionNavScrollToEnd()
        if(!this.isShowingSectionInfo && this.hasPreambleContent()) this.showSectionInfo();
      })
    }
  }
  
  hasPreambleContent() {
    return this.getCurrentSectionPreambleContent()?.length;
  }
  
  hasAmbleContent() {
    return this.hasPreambleContent() || this.hasPostambleContent();
  }

  getAlertNumUnfilledSlug(): string {
    if(this.isPj){
      if(this.checkTag(KNOWN_TEST_RUNNER_TAGS.PJ_LANG))
        return 'alert_pj_unfilled_questions'
      else if(this.checkTag(KNOWN_TEST_RUNNER_TAGS.PJ_MATH))
        return 'alert_pj_math_unfilled_questions'
    } else if(this.checkIsOsslt()) {
      return 'osslt_alert_unfilled_questions';
    } else  {
      if(this.asmtFmrk.partitions?.length === 1 || this.frameWorkTagsRef.get('NO_STAGE_REF')) {
        return 'g9_alert_linear_unfilled_questions';
      }
      return 'g9_alert_unfilled_questions';
    } 
  }

  private gotoNextSection(_options?:{forceSectionIndex?: number}){
    const options = _options || {};
    sessionStorage.setItem("totalFilledBySection", JSON.stringify(this.totalFilledBySection));
    this.clearTools();
    let nextSectionIndex;
    if (options.forceSectionIndex !== undefined && options.forceSectionIndex !== null){
      nextSectionIndex = options.forceSectionIndex
    }
    else {
      if(this.whitelabel.getSiteFlag('ENFORCE_ALLOWED_SECTIONS') && this.sectionsAllowed && this.testState.currentSectionsAllowedIndex !== undefined) {
        nextSectionIndex = this.sectionsAllowed[++this.testState.currentSectionsAllowedIndex];
      } 
      else {
        nextSectionIndex = this.testState.currentSectionIndex + 1;
      }
    }
    const nextSection = () => {

      return this.processRouting(nextSectionIndex)
      .then(()=>{
        this.updatePosition(nextSectionIndex, nextQuestionIndex);
        // console.log('updatePosition')
        try {
          this.closePassage();
        }
        catch(e){}
        this.lastFillState = this.isCurrentQuestionFilled();

        if(!this.isShowingPostambleInfo) {
          return this._saveQuestion()
        } else {
          return Promise.resolve();
        }
      }).then(() => {
        const isPreambleAvail = this.hasPreambleContent();
        if (isPreambleAvail) {
          this.showSectionInfo();
          this.preambleIndex = 0;
        }
        else {
          this.hideSectionInfo();
        }
        this.initFirstQuestion();
        this.questionNavScrollToStart()
        // console.log('section', this.getCurrentSection())
      })
    } 
    const nextQuestionIndex = 0;
    if(this.hasPostambleContent()) {
      if(this.savePosition) {
        const data: any = {section_index: nextSectionIndex, question_index: nextQuestionIndex, question_caption: this.getQuestionTitle(nextQuestionIndex)};
        if(this.testFormType === TestFormConstructionMethod.MSCAT) {
          data.module_id = this.getNextModuleId(this.testState.currentSectionIndex, true)
        }
        this.savePosition(data); //save the next section and question index on backend, so on refresh we are not able to submit this section again.
      }
      this.showPostambleInfo(nextSection);
      this.postambleIndex = 0;
    } 
    else {
      nextSection();
    }

    this.updateSectionAccessDate();
  }

  async updateSectionAccessDate() {
    const sectionDataRaw = await this.getSectionData();

    if (sectionDataRaw && sectionDataRaw.sections_meta){
      const sectionData: any = JSON.parse(sectionDataRaw.sections_meta);
      const section = this.getCurrentSection();
  
      // If the section data has never been initialized, it is safe to assume this is their first time pausing.
      if(!sectionData.hasOwnProperty(`${section.sectionId}`) || !sectionData[`${section.sectionId}`].hasOwnProperty('accessed_on')) {
        return await this.updateSectionMeta(sectionDataRaw.id, {section_id: section.sectionId}, {query: {isUpdateAccess: true}});
      }
    }
  }

  private processRouting(nextSectionIndex:number){
    return new Promise<void>((resolve, reject) => {
      if (this.testFormType === TestFormConstructionMethod.MSCAT){
        this.loadNextModule();
        resolve();
      }
      else{
        this.ensureSectionPathFill(nextSectionIndex)
          .then(resolve)
          .catch(reject)
      }
    })
  }

  isDrawingToolsShown(){
    return true;//!this.isFlushNavigation();
  }

  private ensureSectionPathFill(sectionIndex:number, isConfirmationReq:boolean = true, isSilentPass:boolean = false){
    return new Promise<void>((resolve, reject) => {
      
      const onReject = (msg:string) => {
        if (isSilentPass){
          resolve();
        }
        else{
          this.loginGuard.quickPopup(
            this.lang.tra(errorDetected.message),
          )
          reject()
        }
      }

      const sectionPathOptions = this.testRunnerPathSections.get(sectionIndex);
      if (!sectionPathOptions){
        return resolve();
      }
      const targetSection = this.testRunnerSections[sectionIndex];
      if (!targetSection || targetSection.questions.length > 0){ // do not refill
        return resolve();
      }
      let sectionToInject:{
        sectionDef: ISectionDef,
        entryState:IEntryStateMcq,
        selectionIndex: number,
      }
      let errorDetected:{message: string};
      let MESSAGE_NO_SELECTION = 'lbl_student_path_no_sel';
      if (this.asmtFmrk && this.asmtFmrk.msgPathWarnOverride){
        MESSAGE_NO_SELECTION = this.asmtFmrk.msgPathWarnOverride;
      }

      sectionPathOptions.forEach(sectionDef => {
        if (errorDetected){ return; }
        // check
        const itemId = +sectionDef.conditionOnItem;
        const deciderItemState = this.getQuestionState(itemId);
        if (!deciderItemState){
          return errorDetected = {message: MESSAGE_NO_SELECTION}
        }
        const entryStates = this.getQuestionStateEntries(deciderItemState);
        if (!entryStates){
          return errorDetected = {message: MESSAGE_NO_SELECTION}
        }
        // entryStates.forEach((entryState:IEntryStateMcq) => {
        const entryState:IEntryStateMcq = entryStates[0];
        if (!entryState || entryState.selections.length < 1){
          return errorDetected = {message: MESSAGE_NO_SELECTION}
        }
        const selection = entryState.selections[0]; // assuming its not multi select
        const selectionIndex = +selection.i;
        if (selectionIndex === +sectionDef.conditionOnOption){
          if (!deciderItemState.__meta){
            deciderItemState.__meta = {};
          }
          sectionToInject = {
            sectionDef,
            entryState,
            selectionIndex
          };
        }
      })

      if (!sectionToInject && !errorDetected){
        errorDetected = {message: MESSAGE_NO_SELECTION}
      }

      console.log('path selection', errorDetected, sectionToInject);

      if (errorDetected){
        onReject(errorDetected.message);
      }
      else if (sectionToInject){
        const sectionToFill = this.testRunnerSections[sectionIndex];
        const completeInjection = () => {
          Object.keys(sectionToInject.sectionDef).forEach(key => {
            if (key !== '__meta'){
              sectionToFill[key] = sectionToInject.sectionDef[key];
            }
          })
          sectionToInject.entryState.isPathFollowed = true;
        }
        if (isConfirmationReq){

          const asmtFmrk:any = this.asmtFmrk || {};

          const caption = this.lang.tra(asmtFmrk.msgPathCnfmOverride || 'lbl_student_path_cant_undo');
          const btnCancelCaption = asmtFmrk.msgPathCnfmCancelOverride || 'lbl_student_path_back';
          const btnProceedCaption = asmtFmrk.msgPathCnfmProceedOverride || 'lbl_student_path_proceed';
          
          return this.loginGuard.confirmationReqActivate({
            caption,
            btnCancelConfig: {
              caption: btnCancelCaption
            },
            btnProceedConfig: {
              caption: btnProceedCaption
            },
            confirm: ()=> {
              completeInjection()
              resolve()
            },
          })
        }
        else if (isSilentPass){
          completeInjection()
          resolve()
        }
        else{
          return resolve()
        }
      }
      else{
        onReject('Unknown error in choosing path');
      }
    })
  }

  private getItemSelectionIndex(){

  }


  getNextModuleId(currentSectionIndex, log:boolean = false){
    const currentSection:IPanelModuleDef = this.getSection(currentSectionIndex);
    const currentModuleId = currentSection.moduleId;
    let nextRoute:{module:string, minPropC:number, maxPropC};
    let panelRouting = this.currentTestDesign.panelRouting[''+currentModuleId] || this.currentTestDesign.panelRouting[currentModuleId];
    let propC = this.computePercentageCorrect(currentSectionIndex); // to do: this is misnamed
    console.log('propC', propC)
    let targetLevel = 0;
    try {
      if (this.currentTestDesign.isPanelRoutingByNumCorrect){
        // console.log('propC', propC)
        panelRouting.forEach(route => {
          if ( (route.minPropC === undefined) || (propC >= route.minPropC) ){
            if ( (route.maxPropC === undefined) || (propC < route.maxPropC) ){
              nextRoute = route;
            }
          }
        })
      }
      else{
        throw new Error();
      }
    }
    catch(e){}

    if (!nextRoute && panelRouting){
      console.warn('Number-Correct routing failed or is not enabled for this assessment panel, you will be routed to a random module option.');
      nextRoute = randArrEntry(panelRouting);
    }
    let targetModuleId
    if(nextRoute){
     targetModuleId = +nextRoute.module;
    }
    if(log && this.testAttemptId) {
      const logData = {
        uid: this.auth.getUid(),
        test_attempt_id: this.testAttemptId,
        current_section_index: currentSectionIndex,
        current_module_id: currentModuleId,
        target_module_id: targetModuleId,
        target_level: targetLevel,
        question_states: JSON.stringify(this.getCurrentQuestionStates())
      }
      this.auth.apiCreate(this.routes.STUDENT_STAGE_SUBMISSION, logData);
    }

    return targetModuleId;
  }

  loadNextModule(){
    const currentSectionIndex = this.testState.currentSectionIndex;
    const moduleId = this.getNextModuleId(this.testState.currentSectionIndex, true)
    this.loadQuestionsForModuleId(moduleId, currentSectionIndex+1);
    this.initSection();
  }

  moduleToSectionProps:{from:string, to?:string}[] = [
    {from:'questions',}, // most important!
    {from:'hasFormulas',},
    {from:'moduleId',},
    {from:'postambleList',},
    {from:'preambleList',},
    {from:'sidebarThumbnailEn',},
    {from:'sidebarThumbnailFr',},
    {from:'sidebarThumbnailFr',},
  ]

  loadQuestionsForModuleId(moduleId:number, targetSectionIndex:number){
    console.log('loadQuestionsForModuleId', moduleId, this.currentTestDesign.panelModules)
    let nextPanelModule;
    nextPanelModule = this.getModule(moduleId);
    if (!nextPanelModule){
      nextPanelModule = this.currentTestDesign.panelModules[1]; // gross temp
    }
    this.testState.currentModuleId = +nextPanelModule.moduleId;

    const targetSection = this.testRunnerSections[targetSectionIndex]
    console.log('targetSection', targetSection)
    this.moduleToSectionProps.forEach(propMapping => {
      // this will typically include questinos
      const propFrom = propMapping.from;
      const propTo = propMapping.to || propFrom;
      targetSection[propTo] = nextPanelModule[propFrom];
    })

    if (nextPanelModule.orderedBuckets && nextPanelModule.orderedBuckets)
    if (nextPanelModule.orderedBuckets && nextPanelModule.orderedBuckets.length){
      const orderedItemIdsAgg = [];
      for (let bucket of nextPanelModule.orderedBuckets){
        const itemIds = _.shuffle(bucket.itemIds)
        for (let itemId of itemIds){
          orderedItemIdsAgg.push(itemId);
        }
      }
      targetSection.questions = orderedItemIdsAgg;
      console.log('apply question remap', orderedItemIdsAgg.join())
    }
  }

  getModule(moduleId){
    for (let panelModule of this.currentTestDesign.panelModules){
      if (+panelModule.moduleId === +moduleId){
        return panelModule;
      }
    }
  }



  private computePercentageCorrect(sectionIndex:number) {
    let score = 0;
    let scoreMax = 0;
    const states = this.testState.questionStates;
    const section = this.getSection(sectionIndex)
    const module = this.getModule(section.moduleId);
    const routingExclusions = (module?.routingExclusions) || {}
    section.questions.forEach(qId => {
      if (!routingExclusions[+qId]){
        const questionState = states[qId];
        let entryScore = 0;
        let entryScoreMax = 0;
        if (questionState){
          const entries = this.getQuestionStateEntries(questionState);
          entries.forEach(entryState => {
            // console.log('entry', questionState[entryId], questionState[entryId].score);
            if (entryState && entryState.score){
              entryScore += +entryState.score;
            }
            if (entryState.weight) {
              entryScoreMax += (+entryState.weight);
            }
          });
          if (entryScoreMax > 0){
            score += entryScore;
          }
          scoreMax += entryScoreMax;
        }
        else {
          scoreMax += 1; // default score weight
        }
      }
    });
    if (scoreMax > 0){
      return this.precisionScoreCalc(score / scoreMax);
    }
    return 0;
  }
  
  private roundNumeric(num: number){
    return Math.round(100*num)/100; // fixed to 2 decimal places for now
  }

  private precisionScoreCalc(num: number) {
    return +(num.toFixed(6));
  }

  getQuestionTestLabel(question) {
    const questionObj = this.questionSrcDb.get(question)
    console.log(questionObj)
    return questionObj["testLabel"];
  }

  isSecureProxy(){
    return false;
  }

  getQuestionRubric(question:number) {
    const quest:IQuestionConfig = this.getQuestionDef(question)
    let rubric:IContentElement = undefined
    quest.content.forEach((q)=>{
      if (q.elementType==ElementType.SOLUTION && q["isRubric"]) {
        rubric = q;
      }
    })
    return rubric
  }

  goBackAndReviewYesNavigation(submitTest:boolean = false){
    let nextBtnText = '';
    let popup = this.styleProfile.getSectionPopup(this.currentSectionSettings.customSectionPopupSlug);
    
    if (submitTest && this.isFlushNavigation()) {
      nextBtnText = this.lang.tra("alert_KK_SUBMIT_TEST_yes_bc");
    } else if (!submitTest && popup) {
      nextBtnText = popup.nextBtnText;
    } else {
      // backward compatibility
      nextBtnText = this.isFlushNavigation() ? "alert_KK_SUBMIT_SECTION_yes_bc" : "alert_KK_SUBMIT_TEST_yes";
    }
    return nextBtnText
  }
  goBackAndReviewNoSlugs() {
    let popup = this.styleProfile.getSectionPopup(this.currentSectionSettings.customSectionPopupSlug);
    if (popup) {
      return [popup.backBtnText];
    } else {
      //backward compatibility
      let baseSlug = this.isFlushNavigation() ? "alert_KK_SUBMIT_TEST_no_bc" : "alert_KK_SUBMIT_TEST_no";
      return parseSlugList([`${baseSlug}_start`, '{{QUESTION_WORD}}'], {QUESTION_WORD: this.getQuestionWordSlug({isLowerCase: this.isFlushNavigation() || this.lang.c() === 'fr', isPlural: true})})
    }

  }

  private get currentSectionSettings() {
    return this.asmtFmrk.partitions[this.testState.currentSectionIndex];
  }

  zoomIn() {
    if (this.getZoomLevel() + this.zoomIncrement <=  this.maxZoomLevel) {
      this.zoom.update(this.getZoomLevel() + this.zoomIncrement);
    }
    this.updateDragZoomCorrection()
    this.logTool("ZOOM_IN", this.getZoomLevel());
  }
  zoomOut() {
    if (this.getZoomLevel() - this.zoomIncrement >=  this.minZoomLevel) {
      this.zoom.update(this.getZoomLevel() - this.zoomIncrement)
    }
    this.updateDragZoomCorrection()
    this.logTool("ZOOM_OUT", this.getZoomLevel());
  }

  getDocumentTooltip(){
    if (this.documentItems && this.documentItems.length === 1){
      return this.getRefDocCaption(this.documentItems[0]);
    }
    return 'lbl_documents';
  }

  ghostAdded = false;
  getPixelsPerEM() {
    const el = document.getElementById("ghost-test-runner");
    if (!el) return 0;
    if (!this.ghostAdded) {
      //document.body.appendChild(el);
      const parent = document.getElementById("left-split-container");
      parent.appendChild(el);
      this.ghostAdded = true;
    }
    const width = el.offsetWidth;
    const len = width/10;
    //document.body.removeChild(el);
    return len;
  }

  getLeftReadingPassage() {
    const element = document.getElementById("readingPassageSplit")
    return element.offsetLeft+'px'
  }

  getTopReadingPassage() {
    const element = document.getElementById("readingPassageSplit")
    return element.offsetTop+'px'
  }

  getLeftDrawingOverlayStyle() {
    const style:any={}
    const element = document.getElementById("readingPassageSplit")
    if (element) {
      style["left"] = element.offsetLeft
      style["top"] = element.offsetTop;
    }

    return element;
  }

  getNotepadSlug(){
    if (!this.isFlushNavigation()){
      return 'lbl_notepad'
    }
    else{
      return 'lbl_notepad_bc'
    }
  }

  sendIssueReport() {
    return this.auth.apiCreate(
      this.routes.TEST_TAKER_INVIGILATION_REPORT_ISSUE,
      {
        test_session_id: this.testSessionId,
        question_id: this.getActiveQuestionId(),
        message: this.issueReportMessage.value,
      }
    )
    .then(e => {
      this.isShowingReport = false;
    });
  }
  reportIssue() {
    this.isShowingReport = true;
  }
  checkTimeLeft() {
    this.refreshTimeRemaining.emit();
    // this.activateModal( this.lang.tra('alert_TIME_LEFT'), ()=>{} );
    if (this.checkTime) {
      this.checkTime();
    }
    this.isShowingTime = true;
  }

  getElapsedTestTime(){
    return this.calculateElapsedTime(this.testStartTime)
  }
  
  calculateElapsedTime = (startTime:number) => {
    const currentTime = this.getCurrentTime()
    const elpsedTime = currentTime - startTime
    const hours = Math.floor((elpsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((elpsedTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((elpsedTime % (1000 * 60)) / 1000);
    return `${this.leadingZero(hours)}:${this.leadingZero(minutes)}:${this.leadingZero(seconds)}`    
  }

  getCurrentTime() { return new Date().getTime() }

  getInfoCaption(){
    const section = this.getCurrentSection();
    if (section){
      return section.infoCaption;
    }
  }

  getToolbarInfoSlug() {
    return 'btn_info';
  }

  openChat() {
    if (this.checkChat) {
      this.checkChat();
    }
    this.isShowingChat = true;
  }

  closePassage() {
    // console.log("closing Reading Passage")
    this.clearReadingSelection()
    this.hyperLinkService.linkRequestSecond.next({
      readerElementId: this.currentBookmark,
      readerId: this.currentReadSelection,
    });
    this.rightPageId = 0;
    this.onResize();
  }

  toggleReadingSelections() {
    this.isShowingReadingSelections = !this.isShowingReadingSelections;
  }

  toggleTestNav() {
    this.isTestNavExpanded = !this.isTestNavExpanded;
  }
  toggleToolbar() {
    this.isToolbarExpanded = !this.isToolbarExpanded;
  }
  toggleLineReader() {
    this.isLineReaderActive = !this.isLineReaderActive;
    if(!this.isLineReaderActive)  this.lineReaderWidth = DEFAULT_LINEREADER_WIDTH;
    this.logTool("LINE_READER", this.isLineReaderActive);
  }

  resizeLineReaderWidth(evt, resize){
    evt.stopPropagation()
    const { which: clickType } = evt;
    this.isResizeLineReaderWidth = clickType === 1 && resize ? true : false;
  }

  private _resizeLineReaderWidth(){
    const MAX_WIDTH_PX = 1500
    const MIN_WIDTH_PX = 300

    const { left: lineReaderLeft } = this.lineReader.nativeElement.getBoundingClientRect();

    let width = this.mousePosition.x > lineReaderLeft ? this.mousePosition.x - lineReaderLeft : 0;
    if(width > MAX_WIDTH_PX) width = MAX_WIDTH_PX
    if(width < MIN_WIDTH_PX) width = MIN_WIDTH_PX
    this.lineReaderWidth = width
  }

  toggleNotepad(){
    this.isNotepadEnabled = !this.isNotepadEnabled;
    this.logTool("NOTEPAD", this.isNotepadEnabled);
  }
  toggleHiContrast() {
    this.textToSpeech.hi_contrast_toggle();
    this.logTool("HI_CONTRAST", this.textToSpeech.isHiContrast);
  }

  toggleDocuments() {
    this.isShowDocuments = !this.isShowDocuments;
    if(this.isShowDocuments && this.asmtFmrk.showDocumentsSplitScreen) {
      this.readSelViewHalf();
    }
    this.logTool("SHOW_DOCS", this.isShowDocuments);
  }

  toggleFormula() {
    this.isShowFormulaSheet = !this.isShowFormulaSheet;
    this.logTool("SHOW_FORMULA_SHEET", this.isShowFormulaSheet);
  }

  toggleFormulas() {
    this.isFormulasToggledOn = !this.isFormulasToggledOn;
    if (this.isFormulasToggledOn) {
      this.slowScrollToTop();
    }
    this.logTool("SHOW_FORMULAS", this.isFormulasToggledOn);
  }

  focusCalc() {
    document.getElementById("CalculatorToolbar").focus();
  }

  toggleCalc() {
    this.isCalcToggledOn = !this.isCalcToggledOn;
    if (this.isNBED()){} //Don't want the calculator opened in split screen in NBED
    else {
      if(this.isCalcToggledOn) {
        this.readSelViewHalf();
      } else if(!this.isShowingRight()) {
        this.onResize(); 
      }
    } 
    this.calcState.emit(this.isCalcToggledOn);
    this.logTool("CALC", this.isCalcToggledOn);
    setTimeout(this.focusCalc, 1000);
    // if (this.isCalcToggledOn) {
    //   this.slowScrollToTop();
    // }
  }

  toggleDictionary() {
    this.isDictionaryToggledOn = !this.isDictionaryToggledOn;
    
    this.dictionaryState.emit(this.isDictionaryToggledOn);
    this.logTool("DICTIONARY", this.isDictionaryToggledOn);
  }

  hasBackToMenu = () => this.checkIsOssltTools() || !!this.isTeacherAdminQuestionnaire;

  goBackToMap = () => {
    this._saveQuestion().then(() => {
      sessionStorage.setItem("totalFilledBySection", JSON.stringify(this.totalFilledBySection));
      this.backToMap.emit()
    })
  }

  goBackToMenu = () => {
    if(this.isTeacherAdminQuestionnaire){
      this._saveQuestion().then(() => {
        // save position in test-attempts
      })
    }
    this.backToMenu.emit()
  }

  slowScrollToTop() {
    const el = this.questionDisplay.nativeElement;
    el.scrollIntoView({block: 'start'});
    setTimeout(() => {
      const elt = this.topBar.nativeElement;
      elt.scrollIntoView({behavior: 'smooth', block: 'end'});
    }, 100);
  }

  isLang(langCode: string) {
    return (langCode === this.testLang);
  }

  isShowingCalc() {
    return this.isCalcToggledOn;
  }

  isShowingDictionary() {
    return this.isDictionaryToggledOn;
  }

  getSectionTitleSlug() {

    if(this.frameWorkTagsRef.get('USE_STAGE_TITLE')) {
      return 'title_stage';
    } else if(this.frameWorkTagsRef.get('USE_SESSION_TITLE')) {
      return 'title_session';
    }
    
    if (this.isFlushNavigation()) {
      if (this.lang.c() === 'fr'){
        return 'Partie'
      }
      else{
        return 'Part'
      }
    }
    if (!this.checkIsOsslt()) {
      switch (this.testFormType){
        case TestFormConstructionMethod.MSCAT: return 'title_stage';
        default: return 'title_section';
      }
    } else {
      switch (this.testFormType){
        case TestFormConstructionMethod.MSCAT: return 'title_stage';
        default: return 'title_section_osslt';
      }
    }
  }

  getSectionTimeRemainingSlug() {
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return 'tr_stage_time_remaining';
      default: return 'tr_section_time_remaining';
    }
  }


  getAlertUnfilledWarnP2Slug(): string {
    if(this.frameWorkTagsRef.get('NO_STAGE_REF') || this.asmtFmrk.partitions?.length === 1) {
      return 'alert_UNFILLED_WARN_P2_general'
    }
    if(this.frameWorkTagsRef.get('USE_STAGE_TITLE')) {
      return 'alert_UNFILLED_WARN_P2'
    }
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return this.checkIsOsslt() ? 'alert_UNFILLED_WARN_P2_SECTION' : 'alert_UNFILLED_WARN_P2_STAGE' ;
      default: return  this.checkIsOsslt() ? 'alert_UNFILLED_WARN_P2_SECTION' : 'alert_UNFILLED_WARN_P2';
    }
  }

  getAlertKKSubmitSectionSlug() {

    if(this.frameWorkTagsRef.get('NO_STAGE_REF')) {
      return 'alert_KK_SUBMIT_general';
    }
    if(this.frameWorkTagsRef.get('USE_STAGE_TITLE')) {
      return 'alert_KK_SUBMIT_STAGE'
    }
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return 'alert_KK_SUBMIT_STAGE';
      default: return 'alert_KK_SUBMIT_SECTION';
    }
  }

  getSections(){
    if (this.currentTestDesign && this.currentTestDesign.sections){
      return this.currentTestDesign.sections;
    }
    return []
  }


  getAlertKKSubmitTestSlug() {

    if(this.isPj) {
      return '';
    }

    if(this.isFlushNavigation()) {
      return 'alert_KK_SUBMIT_EVALUATION'
    }

    // switch (this.testFormType){
    //   case TestFormConstructionMethod.MSCAT: return 'alert_KK_SUBMIT_STAGE';
    //   default: return 'alert_KK_SUBMIT_TEST';
    // }

    if (this.testFormType === TestFormConstructionMethod.MSCAT){
      return 'alert_KK_SUBMIT_STAGE';
    }
    else{
      if(this.frameWorkTagsRef.get('GENERAL_SUBMIT_TEST')) {
        return 'alert_KK_SUBMIT_general';
      }
      if (this.getSections().length > 1){
        return 'alert_KK_SUBMIT_TEST';
      }
      else{
        return 'alert_KK_SUBMIT_TEST_single_section'
      }
    }
  }

  logStudentAction(slug:string, info: any, slugPrefix? : string) {
    if(!this.auth.userIsStudent()) {
      return;
    }

    let prefix = slugPrefix || "";
    prefix += "_";
    this.auth.apiCreate( this.routes.LOG,
      {
        slug: `${prefix}${slug}`,
        data: {
          uid: this.auth.getUid(),
          session_id: this.testSessionId,
          state: {
            section_index: this.testState.currentSectionIndex,
            question_index: this.testState.currentQuestionIndex
          },
          info
        }
      }
    )
  }
  logTool(toolSlug: string, info: any) {
    this.logStudentAction(toolSlug, info, "STUDENT_ASMT_TOOL")
  }

  showReportOptions(option){
    let reportOptions = this.asmtFmrk.reportOptions;
    switch (option) {
      case ReportOptions.SCORE:
        return reportOptions ? !reportOptions.hideScore : true
      case ReportOptions.CHECKMARK:
        return reportOptions ? reportOptions.showCheckmark : false
      case ReportOptions.STUDENT_PEN:
        return reportOptions ? !reportOptions.hideStudentPen : true
      case ReportOptions.LEGEND:
        return reportOptions ? !reportOptions.hideLegend : true
      default:
        return;
    }
  }

  getProportionalQuestionScore(qId: number, round?: boolean) {
    let pScore = this.questionPScores.get(qId);
    if(pScore !== undefined) {
      if (round){
        return this.roundNumeric(pScore)
      }
      return pScore;
    } 
    else {
      const qRes = this.questionStates[qId];
      pScore = 0;
      if (qRes){
        let entries = Object.keys(qRes).filter((key) => {
          return !key.startsWith('__') && this.isAutoScorableElement(qRes[key]);
        });
        entries.forEach(entryId => {
          const entryState = qRes[entryId];
          // console.log(qId, entryId, pScore)
          pScore += entryState.score;
        })
      }
      this.questionPScores.set(qId, pScore);
      if (round){
        return this.roundNumeric(pScore)
      }
      return pScore;
    }
  }

  getQuestionScoreDisplay(qId: number){
    let score = this.getProportionalQuestionScore(qId, true);
    return score;
  }

  getQuestionScore(qId: number) {
    const correct = this.questionScores.get(qId);
    if(correct === QuestionScore.CORRECT) {
      return this.getQuestionTotalScore(qId);
    } 
    else if(correct === QuestionScore.INCORRECT) {
      return 0;
    } 
    else {
      return 0;
    }

    //all or nothing scoring. Do we award part marks?
  }

  getQuestionTotalScore(qId: number) {
    const question = this.questionSrcDb.get(qId);
    let points;
    if (question){
      points = (<IQuestionConfig>question).points;
    }
    if(!points) {
      return 0;
    }

    const tokens = points.split(/\s/);
    if(!tokens || tokens.length === 0) {
      return 0;
    }

    return +tokens[0];
  }

  getQuestionWeight(qId: number, autoCalc: boolean){
    if(autoCalc){
      const qContent = this.questionSrcDb.get(qId).content
      return this.getQuestionPoints(qContent);
    } 

    return this.getQuestionTotalScore(qId);
  }

  initFinalReportStats(){
    this.finalReportStats = {
      itemScore: {},
      numSRQuestions : 0,
      numCRQuestions : 0,
      numCorrectSRQuestions : 0,
      correctSRScore : 0,
      totalSRScore : 0,
      totalCRScore : 0
    }
  }

  scoreAllQuestions() {
    this.initFinalReportStats()
    const finalObj = this.finalReportStats
    const states = this.questionStates;
    return new Promise<void>((resolve, _) => {
      console.log("in promise")
      this.testRunnerSections.forEach(section => {
        if(section.disableScoring){
          return;
        }
        // console.log('section.questions', section.questions)
        section.questions.forEach(qId => {
          const question = <IQuestionConfig>this.questionSrcDb.get(qId);
          if(question.isReadingSelectionPage) {
            return;
          }
          const qRes = states[qId];
          const totalItemWeight = this.getQuestionTotalScore(qId);
          if(this.isManuallyScored(question)) {
            this.questionScores.set(qId, QuestionScore.UNMARKED);
            finalObj.numCRQuestions++;
            finalObj.totalCRScore += totalItemWeight;
          } 
          else {
            
            const correct = this.isQResAllCorrect(qRes);
            this.questionScores.set(qId, correct ? QuestionScore.CORRECT : QuestionScore.INCORRECT);
            const correctItemScore = this.getProportionalQuestionScore(qId, true);
            finalObj.correctSRScore += correctItemScore; // this.getQuestionScore(qId); 
            if(correct) {
              finalObj.numCorrectSRQuestions++;
            }
            finalObj.totalSRScore += this.getQuestionPoints(question.content);
            finalObj.numSRQuestions++;
            finalObj.itemScore[qId] =  { correctItemScore, totalItemWeight }
          }
        });
      });
      finalObj.totalSRScore = this.roundNumeric(finalObj.totalSRScore);
      // console.log("Final Report Stats:",finalObj)
      let resolveFinalObj = Object.keys(finalObj.itemScore).length === finalObj.numSRQuestions
      /*if(resolveFinalObj)*/ resolve() 
    }) 
  }

  public get isHighContrast() : boolean {
    return this.textToSpeech.isHiContrast;
  }

  getFlagURL() {
    if (!this.isHighContrast) {
      return "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6526/authoring/flag%20(coloured)/1607961301596/flag%20(coloured).svg"
    } else {
      return "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/96360/authoring/flag%20(coloured)/1619201479179/flag%20(coloured).svg"
    }
  }

  getCorrectItemScore(qId: number, round:boolean = false){
    // const qId = this.getActiveQuestionId()
    const item = this.finalReportStats.hasOwnProperty('itemScore') ?  this.finalReportStats.itemScore[qId] : undefined
    if(item) {
      let score = round ? this.roundNumeric(item.correctItemScore) : item.correctItemScore
      if(this.lang.c() === 'fr') return score.toLocaleString('fr-FR')
      return score
    }
    return 0;
  }

  getQuestionPoints(elements: IContentElement[]){
    const entryElements = <Partial<IScoredResponse>[]> identifyQuestionResponseEntries(elements, [], true);
    let pointsTotal = 0;
    entryElements.forEach(entryElement => {
      pointsTotal += +getElementWeight(entryElement)
    });
    return pointsTotal;
  }

  private containsAutoScorableElement(elements: IContentElement[]) {
    if(!elements) {
      return false;
    }
    const entryElements = identifyQuestionResponseEntries(elements, [], true);
    return entryElements?.length 
  }

  triggerPrintDialog() {
    window.print();
  }

  private isAutoScorableElement(element:any) : boolean {
    return checkElementIsEntry(element, true, 'type');
  } 


  private getQResPercentageCorrect(qRes) {
    if(!qRes) {
      return 0;
    }
    let entries = Object.keys(qRes).filter((key) => {
      return !key.startsWith('__') && this.isAutoScorableElement(qRes[key]);
    });

    if(!entries || entries.length === 0) {
      return 0;
    }    

    let numCorrect = 0;
    let totalPossible = 0;
    for(const entryId of entries) {
      const eRes = qRes[entryId];
      if(eRes.isCorrect) {
        numCorrect++;
      } 
      if(eRes.isCorrect !== undefined) {
        totalPossible++;
      }
    }

    if(totalPossible === 0) {
      return 0;
    }
    return numCorrect/totalPossible;
  }


  private isQResAllCorrect(qRes) {
    if(!qRes) {
      return false;
    }
    let entries = Object.keys(qRes).filter((key) => {
      return !key.startsWith('__') && this.isAutoScorableElement(qRes[key]);
    });
    if(!entries || entries.length === 0) {
      return false;
    }    
    for(const entryId of entries) {
      const eRes = qRes[entryId];
      if(!eRes.isCorrect && eRes.isCorrect !== undefined) {
        return false;
      }
    }
    return true;
  }

  isManuallyScored(question) {
    return !this.containsAutoScorableElement(question.content);
  }

  showResultsDetailPages(){
    return !(this.asmtFmrk && this.asmtFmrk.isResultsDetailDisabled)
  }  

  exportResults() {
    this.isPrintMode = true;
  }

  exitExportResults() {
    this.isPrintMode = false;
  }

  isNotTestTaker() {
    return this.isTeacher()
    // try {
    //   return this.auth.user() && this.auth.user().value.accountType !== AccountType.TEST_TAKER
    // }
    // catch(e){
    //   return true;
    // }
  }
  
  getCustomResultsText() {
    return this.asmtFmrk.msgResultsPage;
    // const def = 'tr_results_intro_text_2'
    // const msg = this.asmtFmrk.msgResultsPage;
    
    // return !msg ? def : (msg || def);
  }

  getCustomResultsPage(){
    
    let pageText = ''
    let titleText = `<div class="page-title"><markdown>${this.customResultPageTitle || this.lang.tra('bc-results-page-text')}</markdown></div>`
    let sentences = this.customResultPage.split('\n')
    
    sentences.forEach(sentence => {
      let text = sentence.trim()
      if(text) { pageText += `<div><markdown>${text}</markdown></div>` }
    })

    return titleText + pageText
  }

  getResultTitleSlug(){
    return this.asmtFmrk.isOrale ? 'tr_results_page_orale' : 'tr_results_page'
  }

  getCalcImgUrl() {
    if(this.isEQAO()) {
      if(this.isPj)
        return 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/calc_icon/1639494949806/calc_icon.svg';
      else
        return 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/calculator/1666378359275/calculator.png';
    }
    return "https://d3azfb2wuqle4e.cloudfront.net/UI_Elements/toobar/toolbar_icon-calc.svg";
  }

  getSubmissionText() {
    const def = this.btnReviewSubmit; //'btn_review_submit';
    const section = this.getCurrentSection();
    return !section ? def : (section.submissionText === "Continue" ? 'btn_continue' : section.submissionText || def);
  }
  getNotepadText() {
    const section = this.getCurrentSection();
    if (section && section.notepadText){
      return section.notepadText
    }
    return null
  }
  getExitResultsSlug() {
    return 'tr_btn_exit_results';
  }
  getBackToMapSlug() {
    return 'osslt_back_to_map';
  }
  getBackToMenuSlug() {
    return 'osslt_back_to_menu';
  }
  getReportIssueSlug() {
    return 'btn_report_issue';
  }
  getEqaoLogoSlug() {
    return 'lbl_eqao_logo';
  }

  getNumberSlug(num : number) {
    return `number_${num}`
  }

  isTeacher(){
    if (this.frameWorkTagsRef.get('IS_TEACHER_VIEW')){
      return true;
    }
    return false
  }

  writtenOn:string;
  getWritingDate(){
    if (!this.writtenOn){
      this.writtenOn = moment().format( this.lang.tra('datefmt_day_month_year_dow'));
    }
    return this.writtenOn;
  }

  getAssessmentName(toLower:boolean = false){
    if (this.asmtFmrk){
      // For BCED specific just refactoring the code here
      let assessmentName = this.lang.c() === 'en' ? this.asmtFmrk.assessmentName : this.asmtFmrk.assessmentNameFr;

      if (!assessmentName?.trim()) {
        return '';
      }

      if (this.whitelabel.isBCED() && toLower) {
        const firstCharLower = assessmentName.charAt(0).toLowerCase();
        return firstCharLower + assessmentName.slice(1);
      }
      
      return assessmentName;
    }
  }

  getTestTakerID(){
    return this.testTakerPEN || this.testTakerName || "____________";
  }

  downloadRubric() {
    const wasActive = this.dataGuard.isActive();
    if(wasActive) {
      this.dataGuard.deactivate();
    }
    this.rubricLinkRef.nativeElement.click();
    if(wasActive) {
      this.dataGuard.activate();
    }
  }

  getCalculatorType() {
    if (this.frameWorkTags) {
      for(const slug of this.frameWorkTags){
        if(this.isPj) return CALC["SIMPLE_CALCULATOR"];
        if(slug.slug in CALC) return CALC[slug.slug];
      } 
    }
    return "";
  }

  editItem() {
    this.onEditItem.emit(this.getActiveQuestionId());
  }

  isAllowedSubtitles() {
    if (this.frameWorkTagsRef.get('IS_ALLOWED_SUBS')) {
      return true;
    }
    return false;
  }

  isAllowedTranscripts() {
    if (this.frameWorkTagsRef.get('IS_ALLOWED_TRANSCRIPTS')) {
      return true;
    }
    return false;
  }

  isAllowedAudioPlaybackSpeed() {
    if (this.frameWorkTagsRef.get('IS_ALLOWED_AUDIO_PLAYBACK_SPEED')) {
      return true;
    }
    return false;
  }

  isAllowedVideoPlaybackSpeed() {
    if (this.frameWorkTagsRef.get('IS_ALLOWED_VIDEO_PLAYBACK_SPEED')) {
      return true;
    }
    return false;
  }

  useProgressBySession() {
    return !!this.checkTag(KNOWN_TEST_RUNNER_TAGS.QUESTIONS_FILLED_PROGRESS_BAR) || this.checkIsOsslt()
  }

  isShowingAmble() {
    return this.isShowingSectionInfo || this.isShowingPostambleInfo;
  }

  getCurrentAmbleQ() {
    if(this.isShowingSectionInfo) {
      return this.getCurrentSectionPreambleContent()[this.preambleIndex];
    } else {
      return this.getCurrentSectionPostambleContent()[this.postambleIndex];
    }
  }

  currQHasBanner() {
    return !!this.getActiveQuestionContent()?.bannerImage?.url || this.getActiveQuestionContent()?.showBannerHr || this.hasBannerSubtitle() || this.hasBannerTitle();
  }
  getBannerStyle() {
    const currQ = this.getActiveQuestionContent();

    if(currQ?.bannerImage?.url) {
      return {
        'background-image': `url(${currQ?.bannerImage.url})`,
        'background-size': 'cover'
      }
    }
    return {};
  }

  hasBannerSubtitle() {
    const currQ = this.getActiveQuestionContent();
    return currQ?.bannerSubtitle?.caption;
  }

  getBannerSubtitle() {
    const currQ = this.getActiveQuestionContent();
    return currQ?.bannerSubtitle;
  }

  showBannerHr() {
    const currQ = this.getActiveQuestionContent();
    return currQ?.showBannerHr;
  }

  hasBannerTitle() {
    const currQ = this.getActiveQuestionContent();
    return currQ?.bannerTitle?.caption;
  }

  getBannerTitle() {
    const currQ = this.getActiveQuestionContent();
    return currQ?.bannerTitle;
  }

  getBannerHrStyle() {
    const currQ = this.getActiveQuestionContent();
    if(!currQ?.bannerHrColor) {
      return;
    }
    return {
      'background-color': currQ?.bannerHrColor
    }
  }

  getCustomPrevText() {
    if(this.isPj && !this.useCustomPrev(true)) {
      return 'pj_back_btn';
    }
    const currQ = this.getActiveQuestionContent();
    return currQ?.customPrevText || this.getPrevBtnSlug();
  }

  getCustomNextText() {
    if(this.isPj && !this.useCustomNext(true)) {
      return this.getPjNextBtnSlug();
    }

    const currQ = this.getActiveQuestionContent();
    return currQ?.customNextText || this.getNextBtnSlug();
  }

  isCustomNextBold() {
    if(this.isPj && !this.useCustomNext(true)) {
      return true;
    }

    const currQ = this.getActiveQuestionContent();
    return currQ?.customNextBold;
  }

  isCustomPrevBold() {
    if(this.isPj && !this.useCustomPrev(true)) {
      return true;
    }

    const currQ = this.getActiveQuestionContent();
    return currQ?.customPrevBold;
  }

  getCustomPrevFgColor() {
    if(this.isPj && !this.useCustomNext(true)) {
      if(this.isG6) {
        return '#ffffff';
      } else {
        return '#000000'
      }
    }
    
    const currQ = this.getActiveQuestionContent();

    return currQ?.customPrevFgColor || DEF_CUSTOM_BTN_FG_COLOR;
  }

  getCustomPrevBgColor() {

    if(this.isPj && !this.useCustomPrev(true)) {
      if(this.isG6) {
        return '#12365A';
      } else {
        // return '#7dd5f5';
        return '#b0e4f9' // to match pre/post-amble buttons set up 
      }
    }

    const currQ = this.getActiveQuestionContent();

    return currQ?.customPrevBgColor || DEF_CUSTOM_BTN_BG_COLOR;
  }

  getPjNextBtnFgColor() {
    if(this.isG6 && !this.isOnLastQuestion()) {
      return '#ffffff';
    } else {
      return '#000000'
    }
  }

  getPjNextBtnBgColor() {
    if(this.isOnLastQuestion()) {
      if(this.isG6) {
        return G6_SUBMIT_BG_COLOR;
      } else {
        return G3_SUBMIT_BG_COLOR;
      }
    }
    if(this.isG6) {
      return '#12365A';
    } else {
      // return '#7dd5f5';
      return '#b0e4f9' // to match pre/post-amble buttons set up 
    }
  }

  getCustomNextFgColor() {
    if(this.isPj && !this.useCustomNext(true)) {
      return this.getPjNextBtnFgColor();
    }

    const currQ = this.getActiveQuestionContent();

    return currQ?.customNextFgColor || DEF_CUSTOM_BTN_FG_COLOR;
  }

  getCustomNextBgColor() {
    if(this.isPj && !this.useCustomNext(true)) {
      return this.getPjNextBtnBgColor();
    }

    const currQ = this.getActiveQuestionContent();

    return currQ?.customNextBgColor || DEF_CUSTOM_BTN_BG_COLOR;
  }

  getBannerSubtitleMarginBottom() {
    const currQ = this.getActiveQuestionContent();
    return currQ?.bannerSubtitleMarginBottom || 0;
  }

  isTtsEnabled() {
    let isEnabled = this.isText2SpeechEnabled;
    if (this.whitelabel.getSiteFlag('IS_TTS_ALWAYS')){
      return true;
    }
    else {
      // if (this.whitelabel.getSiteFlag('IS_TTS_SOMETIMES')){
      if (!this.asmtFmrk?.toolbarOptions?.tts){
        isEnabled = false;
      }
    }
    return isEnabled;
  }

  getTtsImgSrc() {
    return 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/text-to-speech/1666378061512/text-to-speech.png';
  }

  getBannerOverlaySrc() {
    const currQ = this.getActiveQuestionContent();
    return currQ?.bannerOverlay?.url;
  }

  useCustomPrev(ignorePj: boolean = false) {
    if(this.isPj && !ignorePj && !this.useCustomNext(true)) {
      return true;
    }
    const currQ = this.getActiveQuestionContent();
    return currQ?.useCustomPrev;
  }

  useCustomNext(ignorePj: boolean = false) {
    if(this.isPj && !ignorePj && !this.useCustomPrev(true)) {
      return true;
    }

    const currQ = this.getActiveQuestionContent();
    return currQ?.useCustomNext;
  }
  useCustomNextPrev(ignorePj: boolean = false) {
    return this.useCustomNext(ignorePj) || this.useCustomPrev(ignorePj);
  }

  showCustomButtonsBotRight() {
    if(this.isPj && !this.useCustomNextPrev(true)) {
      return true;
    }
    const currQ = this.getActiveQuestionContent();
    return this.useCustomNextPrev() && currQ?.customButtonPos === CustomButtonPos.BOT_RIGHT;
  }

  showButtonAfterQ() {
    if(this.isPj && !this.useCustomNextPrev(true)) {
      return false;
    }
    const currQ = this.getActiveQuestionContent();
    return !this.useNextPrevButtons() || (this.useCustomNextPrev() && currQ?.customButtonPos === CustomButtonPos.AFTER_CONTENT );
  }

  getCustomBtnAfterQIndent() {
    const currQ = this.getActiveQuestionContent();
    return currQ?.customButtonIndent || 0;
  }

  getZoomInSlug() {
    if(this.checkIsG9()) {
      return 'btn_zoom_in_g9';
    }
    if(this.checkIsOsslt() || this.checkIsOssltTools() || this.isPj) {
      return 'btn_zoom_in_osslt';
    }
    return 'btn_zoom_in';
  }

  getZoomOutSlug() {
    if(this.checkIsG9()) {
      return 'btn_zoom_out_g9';
    }
    if(this.checkIsOsslt() || this.checkIsOssltTools() || this.isPj) {
      return 'btn_zoom_out_osslt';
    }
    return 'btn_zoom_out';
  }

  getHiContrastSlug() {
    if(this.isABED() || this.isCAEC()){
      return "abed_btn_hi_contrast"
    }
    if(this.checkIsG9()) {
      return 'btn_hi_contrast_g9';
    }
    if(this.checkIsOsslt() || this.checkIsOssltTools() || this.isPj) {
      return 'btn_hi_contrast_osslt';
    }
    return 'btn_hi_contrast';
  }
  
  showNextStar() {
    return this.isPj && !this.useCustomNextPrev(true) && this.isOnLastQuestion();
  }

  openAccSettingsModal() {
    this.pageModal.newModal({
      type: TrModal.ACC_SETTINGS,
      config: {},
      finish: () => {},
      isProceedOnly: true
    })
  }

  cModal() {
    return this.pageModal.getCurrentModal();
  }
  cmc() {
    return this.cModal().config;
  }

  getAccessibilityIconSrc() {
    if(this.isPj) {
      return 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/v4Asset_81/1642783446947/v4Asset_81.svg';
    } 

    return 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/v5Asset_83/1642792747895/v5Asset_83.svg';
  }

  isAccessibilitySettingsAvail() {
    return this.hasConfigurableAccSettings;
  }

  getAccSettingsSlug() {
    return 'accessibility_settings';
  }

  getResultSummaryTitleSlug(){
    if (this.isABED()){
      return 'tr_results_summary_title_ABED';
    }
    return 'tr_results_summary_title';
  }

  getResultSummaryText1Slug(){
    if (this.isABED()){
      return 'tr_results_summary_text_1_ABED';
    }
    return 'tr_results_summary_text_1';
  }

  getResultSummaryText2Slug(){
    if (this.isABED()){
      return 'tr_results_summary_text_2_ABED';
    }
    return 'tr_results_summary_text_2';
  }

  getResultInstructionTitleSlug(){
    if (this.isABED()){
      return 'tr_results_instr_title_ABED';
    }
    return 'tr_results_instr_title';
  }

  getResultsLegendText2Slug(){
    if (this.isABED()){
      return 'tr_results_legend_text_2_ABED';
    }
    return 'tr_results_legend_text_2';
  }

  getResultsLegendText3Slug(){
    if (this.isABED()){
      return 'tr_results_legend_text_3_ABED';
    }
    return 'tr_results_legend_text_3';
  }

  initFpsCheck() {
    //check number of frame per second
    this.backgroundDiagnosticsInterval = setInterval(() => {
      this.runFpsCheck();      
    }, FPS_CHECK_FREQENCY)
  }

  runFpsCheck(){
    const nowTime = new Date();
    if (nowTime.getTime() > this.lastFrameLoopCheck.getTime() + FPS_CHECK_FREQENCY) {
      //calcualte fps
      const thisFrameTime = (nowTime.getTime() - this.lastFrameLoopCheck.getTime());
      const theFPS = this.frameCount * SECOND_MS / thisFrameTime
      const fpsFixed = +(theFPS.toFixed(2));
      console.log("fps: "+fpsFixed);
      if(!this.currentFPS || !this.userIdling){  // update fps at begining or when user is not idling
        this.currentFPS = fpsFixed
        this.setFPSStatus()
      }
      this.lastFrameLoopCheck = nowTime
      this.frameCount = 0;
    }
  }

  showSectionDropdown(){
    return this.checkTag(KNOWN_TEST_RUNNER_TAGS.SECTION_DROPDOWN);
  }

  sectionIdDropdownSelection:number
  onSectionDropdownChange(sectionIndex:number){ // section:ISectionDef
    // const sectionIndex = this.testRunnerSections.indexOf(section);
    this.gotoNextSection({forceSectionIndex: sectionIndex});
  }

  setFPSStatus(){
    if(this.currentFPS > GOOD_FPS){
      this.currentFpsStatus = FPSStatus.GOOD
    }else if (this.currentFPS > POOR_FPS){
      this.currentFpsStatus = FPSStatus.ADEQUATE
    }else{
      this.currentFpsStatus = FPSStatus.POOR
    }

    switch(this.currentFpsStatus){
      case FPSStatus.GOOD:
        this.currentFpsStatusText = this.lang.tra('ts_fps_status_good')
        break;
      case FPSStatus.ADEQUATE:
        this.currentFpsStatusText = this.lang.tra('ts_fps_status_adequate')
        break;  
      case FPSStatus.POOR:
      default:
        this.currentFpsStatusText = this.lang.tra('ts_fps_status_poor')
        break;
    }
  }

  checkTimeOut() {
    this.timeoutId = setTimeout(() => this.userInactive.next(undefined), IDLE_CHECK_FREQENCY);
  }


  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  @HostListener('window:mousemove')
  refreshUserState() {
    clearTimeout(this.timeoutId);
    this.userIdling = false
    this.checkTimeOut();
  }
}
